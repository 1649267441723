import styled from "styled-components";
import { HashLink as Link } from "react-router-hash-link";

export const Container = styled.div`
  width: 88%;
  margin: 0 auto;
  @media (min-width: 576px) {
    width: 540px;
  }
  @media (min-width: 768px) {
    width: 720px;
  }
  @media (min-width: 992px) {
    width: 960px;
  }
  @media (min-width: 1200px) {
    width: 1140px;
  }
  @media (min-width: 1400px) {
    width: 1320px;
  }
  height: 100%;
  position: relative;
`;
export const FlexContainerTwoElem = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: 768px) {
    flex-direction: row;
    align-items: center;
  }
`;

export const Content = styled.div`
  width: 100%;
  margin-top: 5rem;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  gap: 2.2rem;
  @media (min-width: 768px) {
    width: 50%;
    margin-top: 0;
  }
`;

export const ContentHeader = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-bottom: 4rem;
`;
export const ContainerWithLine = styled.div`
  padding: 2rem 3rem 2rem 2rem;
  margin-bottom: 8rem;
  margin-top: 4rem;
  border-left: 1px solid #27aae1;
  @media (min-width: 768px) {
    margin-bottom: 4rem;
    margin-top: 0;
  }
`;

export const Section = styled.section`
  margin: 15rem 0;
  @media (min-width: 768px) {
    margin: 12rem 0;
  }
`;

export const Image2ndPlaceContainer = styled.div`
  order: -1;
  width: 100%;
  img {
    width: 100%;
  }
  @media (min-width: 768px) {
    width: 50%;
    order: 2;
    padding-left: 8rem;
  }
`;

export const CardsContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
  flex-wrap: wrap;
  margin: 4rem 0;
  gap: 2rem;
`;
export const ButtonContainer = styled.div`
  margin-top: 14rem;
  display: flex;
  justify-content: center;
  @media (min-width: 768px) {
    margin-top: 6rem;
  }
`;
export const Title = styled.h2`
  padding-right: 3rem;
  font-size: 4.5rem;
  font-weight: bold;
  text-transform: capitalize;
  text-align: center;
  margin-block-start: 0;
  margin-block-end: 0;
  @media (min-width: 768px) {
    font-size: 3.7rem;
  }
`;

export const BigParagraph = styled.p`
  font-size: 3.3rem;
  @media (min-width: 768px) {
    font-size: 2.4rem;
  }
  &.txt-center {
    text-align: center;
  }
`;

export const Paragraph = styled.p`
  font-size: 3rem;
  @media (min-width: 768px) {
    font-size: 2rem;
  }
`;

export const Blue = styled.span`
  color: #27aae1;
`;
export const Background = styled.section`
  background: #eff2f7;
  position: relative;
  overflow-y: initial !important;
  width: 100%;
  padding: 12rem 0 12rem 0;
  @media (min-width: 768px) {
    padding: 12rem 0 12rem 0;
  }
`;
export const PaddingWhiteSegments = styled.section`
  padding: 12rem 0;
`;
export const ButtonOnClick = styled.button`
  cursor: pointer;
  transition: 0.3s ease-in;
  text-decoration: none;
  width: fit-content;
  background-color: #27aae1;
  border: 1px solid #27aae1;
  border-radius: 8px;
  color: #fff;
  font-size: 2.9rem;
  padding: 13px 25px;
  text-transform: none;
  &:hover {
    background-color: transparent;
    color: #27aae1;
    transition: 0.3s ease-in;
  }
  @media (min-width: 768px) {
    font-size: 2rem;
  }
`;

export const ButtonLink = styled(Link)`
  cursor: pointer;
  transition: 0.3s ease-in;
  text-decoration: none;
  width: fit-content;
  background-color: #27aae1;
  border: 1px solid #27aae1;
  border-radius: 8px;
  color: #fff;
  font-size: 2.9rem;
  padding: 13px 25px;
  text-transform: none;
  &:hover {
    background-color: transparent;
    color: #27aae1;
    transition: 0.3s ease-in;
  }
  @media (min-width: 768px) {
    font-size: 2rem;
  }
`;
export const SubTitle = styled.h3`
  font-size: 3rem;
  font-weight: 600;
  text-transform: uppercase;
  word-spacing: 1rem;
  letter-spacing: 0.4rem;
  color: #27aae1;
  text-align: center;
  margin-left: -20px;
  margin-block-start: 0;
  margin-block-end: 0;
  @media (min-width: 768px) {
    font-size: 1.8rem;
  }
`;
