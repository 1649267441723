import React, { useEffect } from "react";
import FancySlider from "../components/Carousel/FancySlider";
import AboutUs from "../components/Home/AboutUs";
import { slides } from "../components/Carousel/fancy-slider-constant";
import OurServicesFlipCards from "../components/Home/OurServicesFlipCards";
import HowITWork from "../components/Home/HowITWork";
import Outsourcing from "../components/Home/Outsourcing";
import Slider from "../components/Carousel/Slider";
import OurPartners from "../components/Our-Partners/OurPartners";

const widthScreen = window.screen.width;

const Home = () => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <>
      {widthScreen > 500 ? <FancySlider slides={slides} /> : <Slider />}
      <AboutUs link="/company" />
      <OurServicesFlipCards />
      <Outsourcing />
      <HowITWork />
      <OurPartners />
    </>
  );
};

export default Home;
