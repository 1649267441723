import React, { useEffect } from 'react'
import bigImage from '../assets/images/developers-team.jpg'
import smallImage from '../assets/images/developers-team-mini.jpg'
import ComponentWImgTitleCentered from '../components/Outsourcing/ComponentWImgTitleCentered'
import { content } from '../components/Outsourcing/outsourcing-data-constant'
import ImageHeaderContainer from '../components/Generic-Components/ImageHeaderContainer'



const Outsourcing = () => {

  useEffect(() => {
    window.scroll(0,0);
  }, [])
 
  const contentHeader = {
    title:["Contract and Permanent",{span:" IT Recruitment"} ],
    paragraphs:[{line:['Need good people? Fast? OneX has smart recruiters using smart technology. Any IT job, any industry, anywhere in North America']},
    {line:['OneX gets IT.']},{line:['Get started! Call +1 (980) 335-9813 or']}
  ],
    buttonValue:"Register a Vacancy",
    background: [bigImage,smallImage]
  }


  return (
    <>
    <ImageHeaderContainer
    background={contentHeader.background}
    title={contentHeader.title}
    paragraphs={contentHeader.paragraphs}
    buttonValue={contentHeader.buttonValue}
    filter
    multipleParagraphs
    smallText
    />

    {content.map((elem,ind)=>(
    <ComponentWImgTitleCentered
      title={elem.title}
      img={elem.img}
      paragraphs={elem.paragraphs}
      subtitle={elem.subtitle}
      key={ind}
      inverse={ind%2==0?false:true}
      buttonText={elem.buttonText?elem.buttonText:false}
      link={elem.link?elem.link:false}
    />))}
    </>
  )
}

export default Outsourcing







