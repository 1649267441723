import React, { useEffect } from 'react'
import { content } from '../components/Data-Consulting/data-consulting-constant'
import ImageHeaderContainer from '../components/Generic-Components/ImageHeaderContainer'
import bigImage from '../assets/images/business-adviser-analazyng.jpg'
import smallImage from '../assets/images/business-adviser-analazyng-small.jpg'
import GenericContainer from '../components/Generic-Components/GenericContainer'

const DataConsulting = () => {

  useEffect(() => {
    window.scroll(0,0);
  }, [])

  const contentHeader = {
    title:['Data Consulting'],
    background: [bigImage,smallImage]
  }

  return (
    <>
        <ImageHeaderContainer
        background={contentHeader.background}
        title={contentHeader.title}
        filter
        />
        <GenericContainer
        content={content}
        />
    </>
  )
}

export default DataConsulting