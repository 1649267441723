import background from '../../assets/images/workspace-woman.jpg'
import agile from '../../assets/images/agile-development-team.jpg'


export const modal = {
    title:'Some Principles of Agile',
    background,
    bigSize:true,
    sections:[
        {
            images:[agile],
            subtitle:"What are the Core Values of Agile ?",
            paragraphs:[
                "These four core values serve as the foundation of Agile.",
                {blue:"1. Individuals and interactions over tools and processes."},
                "Agile values people more than tools or processes. The people behind the project must answer to all of the needs and changes as they arise, and ultimately drive the development process, not the other way around.",
                {blue:"2. Working software over comprehensive documentation."},
                "Time is of the essence. Developers shouldn’t be tied down documenting each technical specification, plan, test, or approval. Agile provides them with a streamlined process to focus on what they really need to complete the project.",
                {blue:"3. Customer collaboration over contract negotiation."},
                "While negotiating the contract and client requirements is important, getting them involved during the web development process often leads to better results. When a customer participates actively in meetings or periodic demos, they can easily communicate their needs. Hence, they will more likely end up satisfied with the product.",
                {blue:"4. Responding to change over following a plan."},
                "Change was often regarded as an overhead cost so developers wanted to avoid it. But based on the Agile Manifesto, change like new programming languages or technologies can be a valuable tool for creating excellent products. Developers are encouraged to respond urgently to these changes, as well as to those that are raised by their customers, users, and the market.",
          

            ]
        },
        {
           
            subtitle:"What are the Pros of Agile ?",

            paragraphs:[
                {
                    ul:[
                        "Less tedious and time-consuming approach to web development",
                        "Able to maximise the available resources efficiently, reducing waste and energy",
                        "Increased flexibility to accept changes",
                        "Frequent collaboration and feedback",
                        "Increased project success through people-centred efforts and simple processes",
                        "Customers have more control over the project"
                    ]
                } 
            ]
        },
        {
            subtitle:"Agility Matters",
            paragraphs:[
                "Web development consists of several phases where constant communication between the customer and developer is of utmost priority. Should the need to change some features arise, the developers and the methodology they use must be flexible enough to accommodate those changes without causing significant delays in the process.",
                "However, if customer feedback and requests came after the entire project had been finished, the developers would have to redo everything just to modify it accordingly. Thus, delays can be expected.",
                "Agile eliminates this potential problem as it divides a web project into several phases that can be completed one by one. It also uses sprints to speed up the development process. Customers are given as much control over the project as the developers themselves. This means any changes they wish to apply can be instantly communicated and implemented, saving everyone time and money."
            ]
        }
    ]
}