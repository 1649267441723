import React from "react";
import { HashLink as Link } from "react-router-hash-link";
import { content } from "./footer-links-constant";
import styled from "styled-components";
import logo_1 from "../../assets/images/logo_1.png";
import { Container } from "../Generic-Components/GenericStyledComponents";
import background from "../../assets/images/footer-background.jpg";
import {
  TiSocialFacebook,
  TiSocialLinkedin,
  /*
  TiSocialTwitter,
  TiSocialInstagram,
  */
} from "react-icons/ti";
import { FACEBOOK_URL, LINKEDIN_URL } from "../../constants";

const Footer = () => {
  const classes = ["one", "two", "three", "four"];

  const year = new Date().getFullYear();

  return (
    <>
      <RelativeEl>
        <Background></Background>

        <FooterContainer background={background}>
          <Container>
            <RelativeEl>
              <GridContainer>
                <BigElement>
                  <SNavbarBrand to="/">
                    <img src={logo_1} alt="" />
                  </SNavbarBrand>
                  <Paragraph>
                    Nearshore+ Software Development teams. &nbsp;Our combination
                    of amazing talent, lower costs, real-time collaboration, and
                    close teaming proximity makes us an effective innovation
                    partner.
                  </Paragraph>
                  <SocialNav>
                    <SocialMed target="_blank" href={FACEBOOK_URL}>
                      <TiSocialFacebook />
                    </SocialMed>
                    <SocialMed target="_blank" href={LINKEDIN_URL}>
                      <TiSocialLinkedin />
                    </SocialMed>
                    {/*
            <SocialMed  target='_blank'href="https://twitter.com">
              <TiSocialTwitter/>
            </SocialMed>
            <SocialMed  target='_blank'href="https://instagram.com">
              <TiSocialInstagram/>
            </SocialMed>
            */}
                  </SocialNav>
                </BigElement>
                {content.map((el, index) => (
                  <SmallElement key={index} className={classes[index]}>
                    <Title>{el.title}</Title>
                    <UlElement>
                      {el.ul.map((li, index) => {
                        if (li.link != "#") {
                          return (
                            <LiElement key={index}>
                              {li.icon && (
                                <IconContainer>{li.icon}</IconContainer>
                              )}
                              <TextContainer>
                                <LinkTo to={li.link}>{li.li}</LinkTo>
                              </TextContainer>
                            </LiElement>
                          );
                        } else {
                          return (
                            <LiElement key={index}>
                              {li.icon && (
                                <IconContainer>{li.icon}</IconContainer>
                              )}
                              <TextContainer>{li.li} </TextContainer>
                            </LiElement>
                          );
                        }
                      })}
                    </UlElement>
                  </SmallElement>
                ))}
              </GridContainer>
              <Line></Line>
              <RightsContainer>
                <AllRights>
                  &copy; {year} OneX Digital Services. All Rights Reserved.
                  <LinkTo to="/privacy-and-legal">&nbsp;Privacy & Legal</LinkTo>
                </AllRights>
                <RightsLinksContainer>
                  <AllRights>
                    <LinkTo to="/"> Home</LinkTo>
                  </AllRights>
                  <AllRights>
                    <LinkTo to="/contact-us">Contact</LinkTo>
                  </AllRights>
                </RightsLinksContainer>
              </RightsContainer>
            </RelativeEl>
          </Container>
        </FooterContainer>
      </RelativeEl>
    </>
  );
};

export default Footer;

const RelativeEl = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`;

const Background = styled.div`
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  left: 0;
  right: 0;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 0;
`;
const FooterContainer = styled.footer`
  background-image: url(${(props) => props.background});
  background-position: center center;
  background-size: cover;
  height: 100%;
  width: 100%;
`;
const GridContainer = styled.div`
  display: grid;
  padding-top: 4rem;
  grid-template-rows: 31% 15% 10% 44%;
  grid-template-columns: 100%;
  grid-template-areas:
    "bigElement"
    "smallOne"
    "smallTwo"
    "smallThree";
  @media (min-width: 768px) {
    grid-template-columns: 25% 18% 18% 39%;
    grid-template-rows: 100%;
    grid-template-areas: "bigElement smallOne smallTwo smallThree";
  }
  @media (min-width: 992px) {
    grid-template-columns: 25% 22% 25% 28%;
    grid-template-rows: 100%;
    grid-template-areas: "bigElement smallOne smallTwo smallThree";
  }
  @media (min-width: 1200px) {
    grid-template-columns: 25% 25% 25% 25%;
    grid-template-rows: 100%;
    grid-template-areas: "bigElement smallOne smallTwo smallThree";
  }
`;

const BigElement = styled.div`
  grid-area: bigElement;
  margin: 0 auto;
  text-align: center;
  @media (min-width: 768px) {
    text-align: start;
  }
`;
const SmallElement = styled.div`
  padding-top: 4rem;
  margin: 0 auto;
  text-align: center;
  width: 100%;
  &.one {
    grid-area: smallOne;
    @media (min-width: 1400px) {
      padding-left: 15rem;
    }
  }
  &.two {
    grid-area: smallTwo;
    @media (min-width: 1400px) {
      padding-left: 8rem;
    }
  }
  &.three {
    grid-area: smallThree;
  }
  @media (min-width: 768px) {
    text-align: left;
  }
  @media (min-width: 1200px) {
    padding-left: 2rem;
  }
  @media (min-width: 1400px) {
    padding-left: 4rem;
  }
`;
const UlElement = styled.ul`
  list-style: none;
  color: #ffffff;
  padding: 0;
  font-size: 2.3rem;
  @media (min-width: 768px) {
    font-size: 1.5rem;
  }
`;
const TextContainer = styled.div`
  display: flex;
  padding-top: 2px;
`;
const IconContainer = styled.div`
  font-size: 2.3rem;
  padding-right: 1rem;
  @media (min-width: 768px) {
    font-size: 1.5rem;
  }
`;
const LiElement = styled.li`
  margin-top: 1rem;
  flex-direction: column;
  align-items: center;
  display: flex;
  width: 100%;
  @media (min-width: 768px) {
    flex-direction: row;
    align-items: flex-start;
  }
`;
const SNavbarBrand = styled(Link)`
  img {
    height: 12rem;
    @media (min-width: 768px) {
      height: 9rem;
    }
  }
`;
const Title = styled.h2`
  font-size: 2.5rem;
  font-family: "Bitter", serif;
  color: #ffffff;
  text-transform: uppercase;
  @media (min-width: 768px) {
    font-size: 1.5rem;
  }
`;
const Paragraph = styled.p`
  font-size: 2.2rem;
  margin: 4rem 0 4rem 0;
  color: #ffffff;
  z-index: 99;
  @media (min-width: 768px) {
    font-size: 1.5rem;
    margin: 1.7rem 0 1.7rem 0;
  }
`;
const Line = styled.div`
  border-bottom: 1px solid #ffffff;
  margin-top: 6rem;
`;
const RightsContainer = styled.div`
  text-align: center;
  padding: 3rem 0;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
  @media (min-width: 768px) {
    flex-direction: row;
  }
`;
const AllRights = styled.span`
  font-size: 2rem;
  display: flex;
  flex-direction: column;
  color: #ffffff;
  &.txt-gray {
    color: #9fa1a7;
    @media (min-width: 768px) {
      color: #ffffff;
    }
  }
  @media (min-width: 768px) {
    font-size: 1.5rem;
    flex-direction: row;
  }
`;

const LinkTo = styled(Link)`
  text-decoration: none;
  color: #ffffff;
  &:hover {
    color: #ffffff;
  }
`;
const RightsLinksContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 4rem;
  align-items: center;
`;

const SocialNav = styled.div`
  display: flex;
  align-items: center;
  gap: 2rem;
  @media (max-width: 768px) {
    display: none;
  }
`;
const SocialMed = styled.a`
  font-size: 2rem;
  text-decoration: none;
  color: #ffffff;
  &:hover {
    color: #27aae1;
  }
`;
