import React from "react";
import styled from "styled-components";
import { HashLink as Link } from "react-router-hash-link";
import {BiChevronRight } from "react-icons/bi";

const SubMenu = ({ route }) => {

  return (
    <SMenu  >
      <MenuButton to={route.link}>
        {route.name} <BiChevronRight /> 
      </MenuButton>
      <SubRoutesContainer>
        {route.subRoutes.map((subRoute) => (
          <SubRoute to={subRoute.link} key={subRoute.name}>
            {subRoute.name}
          </SubRoute>
        ))}
      </SubRoutesContainer>
    </SMenu>
  );
};

export default SubMenu;
const SubRoutesContainer = styled.div`
  background-color:#222 ;
  position: absolute;
  min-width: 20rem;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  right: -21rem;
  top:0;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.3s ease-in-out, opacity 0.3s ease-in-out;
`;
const SMenu = styled.div`
  position: relative;
  display: inline-block;
  &:hover ${SubRoutesContainer} {
    visibility: visible;
    opacity: 1;
    cursor: pointer;
  }
`;

const MenuButton = styled(Link)`
  color:#ffffff;
  text-decoration:none ;
  padding: 1rem;
  display:flex ;
  svg{
      align-self:center ;
      margin-left:2rem ;
  }
  &:hover {
    transition: 0.5s ease;
    color: #27AAE1;
  }
`;

const SubRoute = styled(Link)`
  text-decoration: none;
  color: #ffffff;
  padding: 1rem;
  transition: 0.3s ease-in;
  &:hover {
    transition: 0.3s ease-in;
    color: #27AAE1;
  }
`;