import React, { useEffect } from 'react'
import { content } from '../components/Databases-Development/databases-dev-constant'
import bigImage from '../assets/images/cloud-databases.png'
import smallImage from '../assets/images/cloud-databases-small.png'
import ContainerWithImages from '../components/Generic-Components/ContainerWithImages'
import ImageHeaderContainer from '../components/Generic-Components/ImageHeaderContainer'

const DatabaseDev = () => {

  useEffect(() => {
    window.scroll(0,0);
  }, [])

    const contentHeader={
        title:['Database Development'],
        background: [bigImage,smallImage],
        paragraphs:[{line:['Secured and customised web based database applications that can be accessed from anywhere.']}]
    }

  return (
    <>
        <ImageHeaderContainer
        background={contentHeader.background}
        title={contentHeader.title}
        paragraphs={contentHeader.paragraphs}
        filter
        />
        <ContainerWithImages
        content={content}
        />
    </>
  )
}

export default DatabaseDev