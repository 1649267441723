import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

const initialState={
    loadding:false,
    positions:[],
    error:''
}

export const fetchPositions = createAsyncThunk('positions/fetchPositions',()=>{
    return axios.get('http://localhost:4000/positions/open').then(response=>response.data)
})

const positionsSlice = createSlice({
    name:'positions',
    initialState,
    extraReducers:builder=>{
        builder.addCase(fetchPositions.pending,(state)=>{
            state.loadding = true
        })
        builder.addCase(fetchPositions.fulfilled,(state,action)=>{
            state.loadding = false
            state.positions = action.payload
            state.error = ''
        })
        builder.addCase(fetchPositions.rejected,(state,action)=>{
            state.loadding = false
            state.positions = []
            state.error = action.error.message
        })
    }
})

export default positionsSlice.reducer 