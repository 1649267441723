import React from "react";
import { HashLink as Link } from "react-router-hash-link";
import styled from "styled-components";
import { Container } from "../../components/Generic-Components/GenericStyledComponents";

const ImageHeaderContainer = ({
  multipleParagraphs,
  background,
  paragraphs,
  title,
  buttonValue,
  link = "/contact-us",
  filter,
  id,
  smallText,
}) => {
  const widthScreen = window.screen.width;
  return (
    <>
      <ImageContainer filter={filter}>
        <img src={widthScreen > 500 ? background[0] : background[1]} alt="" />
        {filter && <Background></Background>}
        <Container>
          <ContentImageContainer>
            {title && (
              <TitleImage space={paragraphs}>
                {" "}
                {title.map((t, i) => {
                  if (t.span) {
                    return <Blue> {t.span}</Blue>;
                  } else {
                    return t;
                  }
                })}
              </TitleImage>
            )}
            {paragraphs && (
              <ParagraphsContainer mult={multipleParagraphs}>
                {" "}
                {paragraphs.map((p, i) => {
                  return (
                    <Paragraph
                      mult={multipleParagraphs}
                      key={i}
                      className={smallText ? "txt-small" : ""}
                    >
                      {p.line.map((line, ind) => {
                        if (line.span) {
                          return <Blue> {line.span} </Blue>;
                        } else {
                          return line;
                        }
                      })}
                    </Paragraph>
                  );
                })}
              </ParagraphsContainer>
            )}
            {buttonValue && (
              <ButtonContainer>
                <ButtonLink
                  to={link}
                  className="change-white"
                  id={id ? id : ""}
                >
                  {buttonValue}
                </ButtonLink>
              </ButtonContainer>
            )}
          </ContentImageContainer>
        </Container>
      </ImageContainer>
    </>
  );
};

export default ImageHeaderContainer;

const scale = window.devicePixelRatio;

const Background = styled.div`
  background-color: rgba(0, 0, 0, 0.3);
  position: absolute;
  top: 0;
  width: 100%;
  height: 82vh;
  z-index: 1;
  @media (min-width: 390px) {
    height: 63vh;
  }
  @media (min-width: 768px) {
    height: ${60 * scale}vh;
  }
`;
const ImageContainer = styled.div`
  position: relative;
  img {
    height: 82vh;
    width: 100%;
    filter: ${(props) => (props.filter ? "brightness(0.6)" : "none")};
    @media (min-width: 390px) {
      height: 63vh;
    }
    @media (min-width: 768px) {
      height: ${60 * scale}vh;
    }
  }
`;
const ContentImageContainer = styled.div`
  position: absolute;
  bottom: 6rem;
  left: 15%;
  max-width: 70%;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (min-width: 768px) {
    max-width: 52%;
    left: 40%;
    bottom: 8rem;
  }
`;
const TitleImage = styled.h2`
  font-size: 5rem;
  font-weight: 500;
  font-family: "Bitter", serif;
  color: #ffffff;
  margin-bottom: ${(props) => (props.space ? "0" : "5rem")};
  @media (min-width: 768px) {
    font-size: 5rem;
    font-weight: 400;
  }
`;
const ParagraphsContainer = styled.div`
  margin-bottom: ${(props) => (props.mult ? "5rem" : "0")};
`;
const Paragraph = styled.p`
  font-size: 3rem;
  margin: ${(props) => (props.mult ? "0" : "1.7rem 0 5rem 0")};
  color: #ffffff;
  @media (min-width: 768px) {
    font-size: 2rem;
  }
  &.color-black {
    color: #222;
  }
  &.txt-small {
    font-size: 2.2rem;
    @media (min-width: 768px) {
      font-size: 2rem;
    }
  }
`;
const ButtonLink = styled(Link)`
  cursor: pointer;
  transition: 0.3s ease-in;
  text-decoration: none;
  line-height: initial !important;
  background-color: #27aae1;
  border: 1px solid #27aae1;
  border-radius: 8px;
  color: #fff;
  font-size: 2rem;
  padding: 13px 25px;
  text-transform: none;
  box-shadow: 0px 18px 80px rgb(255 201 17 / 19%),
    0px 9.1125px 34.875px rgb(255 201 17 / 13%);
  &:hover {
    background-color: transparent;
    color: #27aae1;
    border: 1px solid #27aae1;
    transition: 0.3s ease-in;
  }
  &.change-white {
    font-size: 3rem;
    @media (min-width: 768px) {
      font-size: 2rem;
    }
    &:hover {
      background-color: transparent;
      color: #ffffff;
      border: 1px solid #ffffff;
      transition: 0.3s ease-in;
    }
  }
`;
const Blue = styled.span`
  color: #27aae1;
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  margin-top: 2rem;
  @media (min-width: 768px) {
    justify-content: flex-end;
    padding-right: 8rem;
  }
`;
