import React from 'react'
import { HashLink as Link } from 'react-router-hash-link'
import styled from 'styled-components'

const FlipCard2DVertical = ({ title, icon, link, content}) => {
    return (
        
            <Card>     
                <CardFront>
                    <CardFrontBody> 
                        <IconContainer>

                           {icon}                   
                        </IconContainer>
                        <Title>{title}</Title>
                        <Paragraph>{content} </Paragraph>
                    </CardFrontBody>
                </CardFront>
                <CardBack>
                    <CardBackBody>
                        <Title className='color-white'>{title}</Title>
                        <Paragraph className='color-white'>{content} </Paragraph>
                        <ButtonLink to={link}>Read More</ButtonLink>
                    </CardBackBody>
                </CardBack>
            </Card>

  )
}

export default FlipCard2DVertical


const IconContainer = styled.div`
color:#27AAE1;
font-size: 12rem;
z-index: 99;
@media (min-width:768px){
font-size: 5rem;
}
`
const CardBack = styled.div`
    transform:perspective(500px) rotateX(180deg);
    -webkit-transform: perspective(500px) rotateX(180deg);
    -moz-transform:perspective(500px) rotateX(180deg);
    -o-transform:perspective(500px) rotateX(180deg);
    background-color: #27AAE1;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    border-radius: 20px;
    transition: 1s;
    -moz-transition:1s;
  
`

const CardFront = styled.div`
    transform:perspective(500px) rotateX(0deg);
    -webkit-transform: perspective(500px) rotateX(0deg);
    -moz-transform:perspective(500px) rotateX(0deg);
    -o-transform:perspective(500px) rotateX(0deg);
    background-color: #FFFFFF;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    border-radius: 20px;
    transition: 1s;
    -moz-transition:1s;
`
const Card = styled.div`
    width:100%;
    height: 55vh;
    position: relative;
    -moz-transition: all 600ms;
    transition: all 600ms;
    @media (min-width:768px){
        width:34rem;
        height: 25rem;
    }
    &:hover ${CardBack} {
     transform:perspective(500px) rotateX(360deg);
    -webkit-transform: perspective(500px) rotateX(360deg);
    -moz-transform:perspective(500px) rotateX(360deg);
    -o-transform:perspective(500px) rotateX(360deg);
    }
    &:hover ${CardFront} {
     transform: perspective(500px) rotateX(180deg);
    -webkit-transform: perspective(500px) rotateX(180deg);
    -moz-transform:perspective(500px) rotateX(180deg);
    -o-transform:perspective(500px) rotateX(180deg);
    }
`
const CardFrontBody = styled.div`
    width: 100%;
    height: 100%;
    text-align: center;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 3rem;
    z-index: 1;
`
const CardBackBody = styled.div`
    width: 100%;
    height: 100%;
    padding: 2rem;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 1;
`

const Title = styled.h2`
font-size:4.5rem ;
font-weight:600;
font-family: 'Bitter', serif;
text-align:center ;
z-index: 99;
&.color-white {
    color: white;
}
@media (min-width:768px){
font-size:2.7rem ;
}
`
const Paragraph = styled.p`
font-size: 3.3rem ;
&.color-white {
    color: white;
}
@media (min-width:768px){
font-size:1.3rem;
}
`

const ButtonLink = styled(Link)`
padding: 8px 16px;
text-decoration: none;
font-size: 2.7rem;
border-radius:20px;
border: 1px solid #ffffff;
color:#ffffff;
transition: 300ms ease;
margin-top: 1rem;
&:hover {
    color:#27AAE1;
    background-color: #ffffff;
    border: 1px solid #27AAE1;
    cursor: pointer;
}
@media (min-width:768px){
font-size:1.3rem;
}
`