import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from "react-redux";
import store from './redux/store';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
*{
  margin:0 ;
  padding:0 ;
  box-sizing:border-box ;
}
html,body{
  height:100% ;
  font-family: 'Open Sans', sans-serif;
  font-size: 62.5%;
  @media (max-width:768px){
    font-size:50% ;
  }
  @media (max-width:450px){
    font-size:40% ;
  }
  body{
    font-size: 1.6rem;
  }
}
`

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <GlobalStyle/>
    <Provider store={store}>
    <App />
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
