import React from 'react'
import styled from 'styled-components'
import ComponentWithImages from './ComponentWithImages'
import { Container } from '../../components/Generic-Components/GenericStyledComponents'

const ContainerWithImages = ({ content, title, paragraphs, buttonText}) => {
  return (
    <>
        <Container>
            {title && <Content>
                <Title>{title} </Title>
                <ParagraphContainer>
                    {paragraphs.map((p,i)=>( <Paragraph key={i}>{p} </Paragraph>))}
                </ParagraphContainer>
            </Content>}
        </Container>
            {content.map((c,index)=>(
                <ComponentWithImages
                 img={c.img}
                 paragraphs={c.paragraphs}
                 title={c.title}
                 inverse={index%2==0?true:false}
                 buttonText={c.buttonText?c.buttonText:buttonText}
                 ul={c.ul?c.ul:false}
                 link={c.link?c.link:'/contact-us'}
                 key={index}
                 modal={c.modal}
                />
            ))}
    </>
  )
}

export default ContainerWithImages



const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items:flex-start;
    margin: 15rem 0 10rem 0;
    @media (min-width: 768px){
    flex-direction: row;
    align-items: center;
    }
`
const ParagraphContainer =styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    @media (min-width: 768px){
        width: 50%;
    }
`
const Title = styled.h2`
    font-size: 5rem;
    width: 100%;
    padding: 2rem;
    font-weight: bold;
    color:#27AAE1;
    @media (min-width: 768px){
        width: 50%;
        font-size: 5.5rem;
    }
`
const Paragraph = styled.p`
    font-size: 3rem;
    padding: 2rem;
    font-weight: 500;
    @media (min-width: 768px){
        font-size: 2.3rem;
    }
`