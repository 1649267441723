import React from "react";
import {
  Container,
  ContentHeader,
  PaddingWhiteSegments,
  SubTitle,
  Title,
} from "../Generic-Components/GenericStyledComponents";
import styled from "styled-components";
import exl_logo from "../../assets/logos/EXL-logo.png";
import prama_logo from "../../assets/logos/Prama-Logo.svg";
import clairvoyant_logo from "../../assets/logos/black-logo-1.svg";

export default function OurPartners() {
  return (
    <PaddingWhiteSegments id="partners">
      <Container>
        <ContentHeader>
          <SubTitle>They trust us</SubTitle>
          <Title>Our Partners</Title>
        </ContentHeader>
      </Container>

      <ImageContainer>
        <img src={exl_logo} alt="exl logo" width={200} />
        <img src={prama_logo} alt="prama logo" width={200} />
        <img src={clairvoyant_logo} alt="clairvoyant logo" width={300} />
      </ImageContainer>
    </PaddingWhiteSegments>
  );
}

const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  gap: 75px;
  justify-content: center;
  align-items: center;
  @media (min-width: 989px) {
    flex-direction: row;
  }
`;
