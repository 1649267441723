import draw from '../../assets/images/databases-cartoon.jpg'
import businessman from '../../assets/images/businessman-databases.jpg'
import dataWoman from '../../assets/images/data-statistics.jpg'


export const content = [
    {
        title:'Custom Build Database',
        paragraphs:['We have worked with companies to revolutionise some of the biggest brands. Our team has developed flexible and scalable enterprise-scale core business applications that are fully capable of integrating with existing software and processes.'],
        buttonText:"Let's Talk",
        img:draw
    },
    {
        title:'Customer Relationship Management- CRM',
        paragraphs:["CRM is an invaluable tool for modern companies. The right CRM solution will help your business grow by providing insights into sales, productivity and customer engagement. CRM systems can improve the way your business interacts with your customers by providing an effective way to regulate and automate sales / marketing communications, customer service and customer support."],
        buttonText:"Let's Talk",
        img:businessman
    },
    {
        title:'Business Intelligence',
        paragraphs:["Do you have challenges in capturing, managing, or using existing business data? Getting an accurate view of business is really difficult. Nowadays business information is maintained through multiple and different systems and manually based processes are time-consuming and error-prone. So identifying issues and fixing it is really ahead breaking the process. OneX Technologies’ Business Intelligence solutions help you tackle data to reduce costs, control your business activities and increase revenue."],
        buttonText:"Let's Talk",
        img:dataWoman
    }
]