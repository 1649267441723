import React from "react"
import {  FaCode,FaCloud, FaBrain,FaDatabase,
FaChartPie } from 'react-icons/fa'
import { TbDeviceDesktopAnalytics } from 'react-icons/tb' 
import { VscServerProcess }  from 'react-icons/vsc'
import { SiFastapi } from 'react-icons/si' 
import { BsFillPersonCheckFill } from 'react-icons/bs'


export const routes = [
    {
        icon:<FaCode/>,
        title:'Web and Mobile Development',
        content:'We develop high quality, robust code to run your applications. Engineering software and design to match the user experience and user interface.',
        link:'/web-mobile-development',
        xOrY:1
    },
    {
        icon:<SiFastapi/>,
        title:'API Development',
        content:'Integrate 3rd party libraries, applications, data and services into new or existing applications. We develop APIs to extend and integrate your application(s) with others.',
        link:'/api-development',
        xOrY:1
    },
    {
        icon:<FaDatabase/>,
        title:'Database And Storage',
        content:'From noSQL to SQL we have you covered. We can lift and shift your database into the cloud.',
        link:'/database-development',
        xOrY:1
    },
    {
      icon:<FaCloud/>,
      title:'Cloud Services',
      content:'We run apps on Amazon Web Services (AWS), Google Cloud Platform (GCP) and Microsoft Azure.',
      link:'/cloud-services',
      xOrY:2
    },
    {
        icon:<FaBrain/>,
        title:'Machine Learning And Artificial Intelligence',
        content:'We train data sets and build custom models.Understand user intents, fulfil requests and read unstructured data.',
        link:'/artificial-intelligence',
        xOrY:2
    },
    {
        icon:<VscServerProcess/>,
        title:'Data Engineering',
        content:'Start using your data to make real-time decisions across your organisation. The impossible is now possible.',
        link:'/data-engineering',
        xOrY:2
    },
    {
        icon:<TbDeviceDesktopAnalytics/>,
        title:'Data Consultancy',
        content:'We develop cross-platform mobile apps with React.js for iOS and Android. Whatever the requirements we can help determine what languages will work best.',
        link:'/data-consulting',
        xOrY:1
    },
    {
        icon:<FaChartPie/>,
        title:'Data Analytics',
        content:'We develop cross-platform mobile apps with React.js for iOS and Android. Whatever the requirements we can help determine what languages will work best.',
        link:'/data-analytics',
        xOrY:1
    },
    {
        icon:<BsFillPersonCheckFill/>,
        title:'Recruitment',
        content:'From facilitated collaboration to the ability to execute an Agile production framework, there are myriad benefits to working with teams that are closely aligned with your time zone.',
        link:'/recruitment',
        xOrY:1
    }
]
 