import React from 'react'
import styled from 'styled-components'
import { Container, SubTitle,Section, Title, ButtonLink, BigParagraph, Paragraph } from '../../components/Generic-Components/GenericStyledComponents'

const AllServicesComponent = ({ title, subtitle, bigParagraph, paragraph, icons, link, inverse}) => {
   

        const widthScreen = window.screen.width;
        if(widthScreen<768) inverse= false;

    return (
        <>
         <Section>
            <Container>
                    <FlexContainer>
              { !inverse &&<ImageContainer inverse={inverse}>
                            {icons.map(icon=>(icon))}
                        </ImageContainer>}
                        <Content>
                            <SubTitle>{subtitle} </SubTitle>
                            <Title>{title} </Title>
                            <BigParagraph>{bigParagraph} </BigParagraph>
                            <ParagraphContainer>
                                <Paragraph>{paragraph} </Paragraph>
                            </ParagraphContainer>
                            <ButtonLink to={link} >More Details</ButtonLink>
                        </Content>
                        { inverse &&<ImageContainer inverse={inverse}>
                            {icons.map(icon=>(icon))}
                        </ImageContainer>}
                    </FlexContainer>
            </Container>
        </Section>
        </>
      )
    }
    
    export default AllServicesComponent
    

    





    const FlexContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    @media (min-width: 768px){
    flex-direction: row;
    align-items: center;
    }
    `
    const ImageContainer = styled.div`
       width: 100%;
       height: 40vh;
       font-size: 17rem;
       display: flex;
       justify-content: center;
       align-items: center;
       margin: 4rem 0 5rem 0;
       gap:5rem;
       padding: 5rem;
       border-radius: 18px;
       background:${props=>props.inverse?'rgb(0,212,255)':'rgb(2,0,36)'} ;
       background:${props=> props.inverse?' linear-gradient(90deg, rgba(0,212,255,1) 0%, rgba(9,9,121,1) 60%, rgba(2,0,36,1) 100%)':'linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 35%, rgba(0,212,255,1) 100%)'} ;
       color:#ffffff;
       -webkit-box-shadow:${props=>props.inverse?'0px 0px 5px 0px rgba(0,0,0,0.75)':'0px 0px 5px 0px rgba(0,0,0,0.75)'} ;
       -moz-box-shadow: ${props=>props.inverse?'0px 0px 5px 0px rgba(0,0,0,0.75)':'0px 0px 5px 0px rgba(0,0,0,0.75)'} ;
       box-shadow: ${props=>props.inverse?'0px 0px 5px 0px rgba(0,0,0,0.75)':'0px 0px 5px 0px rgba(0,0,0,0.75)'} ; 
       @media (min-width: 768px){
       width: 50%;
       height: 45vh;
       margin: ${props=>props.inverse?'0 0 0 5rem':' 0 5rem 0 0'};
       } 
    `
    const Content = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 2.2rem;
    font-weight: 500;
    padding-left: 2rem;
    @media (min-width: 768px){
    width: 50%;
    padding-left: 0;
    justify-content: center;
    }
    `
    
    const ParagraphContainer = styled.div`
    `
 