import dificultData from '../../assets/images/dificult-data.jpg'
import unifiedData from '../../assets/images/unified-data.jpg'

export const content = [
    {
        title:["More Companies Count on Data To Power Their Organizations"],
        subtitle:'Unifying Data',
        paragraphs:['Data will live in disparate systems because organisations utilise an array of platforms, systems and services','Extracting, transforming and loading data into one unified location source for analytics, data sharing, data science, machine learning and prediction is one of the many data hurdles every large organisation faces today.'],
        button:'Learn More',
        modal:{
            title:'Why is unified data so great?',
            sections:[
                {
                    images:[unifiedData],
                    paragraphs:["Companies strive to unify their data because, by default, most data is inaccessible. It’s often scattered throughout the company and divided into information silos among business units and teams. Without a central way to manage data, businesses can’t make informed decisions. Marketing teams can’t accurately measure demand for their product. Product teams can’t fully understand their customer journey, and analytics teams, which are often tasked with breaking down information silos, can’t provide accurate business intelligence to leadership. When companies are able to unify their data, they make all of their business units more productive. But unifying data can pose a tremendous organizational challenge, as well as an engineering one."]
                }
            ]
        }
    },
    {
        title:["Data Warehouses Data Lakehouses"],
        subtitle:'Data Systems and Architectures',
        paragraphs:["Today's best in class data systems involve being cloud first, separating compute and storage and abstracting data from source systems.","Organisations build Data Warehouses or Data Lakehouses to store and centralise massive amounts of data across their organisation. The goal of centralising data is to perform fast analytics on data that is as close to real-time as possible to drive decision making for analysts and business units down to the lowest level employee."],
        button:'Contact Us'
    },
    {
        title:["Setup and Data Pipelines"],
        subtitle:'Data Wrangling',
        paragraphs:["Significant efforts are made to setup the Organisation's ETL layer commonly known as Extract, Transform and Load.","ETL exists because in larger organisations, data lives in siloed source systems. The schema, format, fields, data structures may be incomparable. The first step is to extract the data into a staging platform where transforms can be applied.","Once data transforms have been completed, the data can be loaded into a data warehouse for various activities some of which could involve machine learning and predictive analysis."],
        button:'Learn Why',
        modal:{
            title:'The challenge of creating unified data',
            sections:[
                {
                    images:[dificultData],
                    paragraphs:["The technologies that businesses use to store data are highly fragmented. There are tens of thousands of hardware and software providers that each have their own vernacular, programming languages, syntaxes, and practices. On-premise storage servers may not be able to speak to cloud-hosted business intelligence tools which can’t access virtualized servers. Conventions like application programming interfaces (APIs) can connect systems, but don’t always offer enough functionality. Additionally, not all data is the same. There’s big data, thick data, and structured, unstructured, and multi-structured data. Some systems can only process certain types of data, and each dataset can vary wildly. It is little wonder that 85 percent of companies strive to be data-driven yet only 37 percent claim to be successful at using their data. Most data ecosystems rival the United Nations in complexity. Every application speaks slightly different dialects and they require translators to communicate. Businesses that succeed at unifying their data, are better able to plan, budget, forecast, and build products. For unification, many businesses turn to analytics platforms."]
                }
            ]
        }
    }
]