import React from 'react'
import { HashLink as  Link } from 'react-router-hash-link'
import styled from 'styled-components'
import { MdTouchApp } from 'react-icons/md'
import { Container, Section, CardsContainer } from '../Generic-Components/GenericStyledComponents'


const Extras = () => {
  return (
    <Section>
    <Container>
    <BigTitle>Advantages Of Being With Us</BigTitle>
    <CardsContainer>
      <Card>
        <Title >Innovative Solutions</Title>
        <Paragraph className='color-black'>We help you launch innovative products, manage IT services, and shorten your innovation cycle.</Paragraph>
      </Card>
      <Card>
        <Title>Implement Your Vision</Title>
        <Paragraph className='color-black'>We deliver the Top of Tech Talent to implement your vision in a timely and scalable manner.</Paragraph>
      </Card>
      <Card>
        <Title>Transform Your Business</Title>
        <Paragraph className='color-black'>We reduce your time to market and accelerate your digital transformation.

        </Paragraph>
      </Card>
    </CardsContainer>
  <ButtonContainer>
    <ColorHeader className='blue' to="/contact-us">Get In Touch <MdTouchApp/></ColorHeader>
  </ButtonContainer>
  </Container>
  </Section>
  )
}

export default Extras



const Paragraph = styled.p`
font-size: 3rem ;
margin: 1.7rem 0 5rem 0;
color:#ffffff;
&.color-black {
  color:#222;
}
&.txt-small {
  font-size: 2rem;   
  @media (min-width:768px){
    font-size: 1.6rem;   
  }
}
@media (min-width:768px){
  font-size: 2rem;
}
`

const Card = styled.div`
-webkit-box-shadow: 4px 4px 9px 0px rgba(0,0,0,0.75);
-moz-box-shadow: 4px 4px 9px 0px rgba(0,0,0,0.75);
box-shadow: 4px 4px 9px 0px rgba(0,0,0,0.75);
padding: 2rem;
border-radius: 18px;
width: 100%;
@media (min-width: 768px){
  width: 34rem;
  height: 28rem;
}
`

const Title = styled.h2`
  font-size: 3.5rem;
  font-weight: bold;
  color:#27AAE1;
@media (min-width:768px){
  font-size: 3rem;
}
`


const ButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    padding-top: 15rem;
    @media (min-width: 768px){
    padding-top: 8rem;
    }
`
const ColorHeader = styled(Link)`
    font-size: 4.2rem;
    text-decoration: none;
    font-weight: bold;
    background-image: linear-gradient(90deg,rgba(9,9,121,1)  0%, #3CBFBD 55%, rgba(0,212,255,1) 100%);
    background-position: 0% 100%;
    background-repeat: no-repeat;
    background-size: 0% 2px;
    transition: background-size .3s;
    &:hover, :focus {
      background-size: 100% 2px;
    }
    @media (min-width:768px){
    font-size: 3.4rem;
    }
    &.aquamarine{
      color:#3CBFBD;
      &:hover{
        color:#3CBFBD;
      }
    }
    &.blue{
    color:#27AAE1;
    &:hover{
        color:#27AAE1;
     }
    }
`

const BigTitle = styled.h2`
  font-size: 4.5rem;
  text-align: center;
  margin: 0 auto;
  width: 80%;
  margin-bottom: 5rem;
  color: #27AAE1;
  @media (min-width:768px){
    width: auto;
  }
`