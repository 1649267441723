import React, { useState } from 'react'
import { HashLink as Link } from 'react-router-hash-link'
import styled from 'styled-components'
import Modal from '../Modal/Modal'
import { Container, Section } from '../../components/Generic-Components/GenericStyledComponents'

const GenericComponent = ({ title, paragraphs, subtitle, ul, buttonText, link, inverse, modal }) => {

    const [ isOpen, setIsOpen ] = useState(false);

    if(window.screen.width<768) inverse=true;

    return (
    <Section>

    <RelativeEl>
    <Container>
        <FlexContainer>
            {inverse &&<BigTitleContainer inverse={inverse}>
                {title.map((t,i)=>(<Title key={i}>{t}</Title>))}
            </BigTitleContainer>}
            <Content>
                {subtitle && <Subtitle>{subtitle}</Subtitle>}
                {paragraphs && <ParagraphsContainer>
                    { paragraphs.map((p,i)=>(
                    <Paragraph key={i}>{p}</Paragraph>
                ))}
                </ParagraphsContainer>}

                { ul && <UlElement>
                    {ul.map((li,i)=>(<LiElement key={i}>{li}</LiElement> ))}
                </UlElement>}
                { !modal && <ButtonLink to={link}>{buttonText}</ButtonLink>}
                { modal && <Button onClick={()=>setIsOpen(true)}>{buttonText}</Button> }
            </Content>
            {!inverse &&<BigTitleContainer inverse={inverse}>
                {title.map((t,i)=>(<Title key={i}>{t}</Title>))}
            </BigTitleContainer>}
        </FlexContainer>
    </Container>
    { modal && <Modal setIsOpen={setIsOpen} isOpen={isOpen} modal={modal} />}
    </RelativeEl>
    </Section>
  )
}

export default GenericComponent

const RelativeEl = styled.div`
  position: relative;
  overflow-y: initial !important;
  width: 100%;
`


const FlexContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap:5rem;
    height: 100%;
    @media (min-width: 768px){
    flex-direction: row;
    align-items: center;
   }
`
const BigTitleContainer = styled.div`
    h2{
    
    }
    width: 100%;
    height: 40vh;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    border-radius: 18px;
    background: rgb(2,0,36);
    background:${props=>!props.inverse?'rgb(0,212,255)':'rgb(2,0,36)'} ;
     background:${props=> !props.inverse?' linear-gradient(90deg, rgba(0,212,255,1) 0%, rgba(9,9,121,1) 60%, rgba(2,0,36,1) 100%)':'linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 35%, rgba(0,212,255,1) 100%)'} ;
     color:#ffffff;
    -webkit-box-shadow:${props=>!props.inverse?'0px 0px 5px 0px rgba(0,0,0,0.75)':'0px 0px 5px 0px rgba(0,0,0,0.75)'} ;
    -moz-box-shadow: ${props=>!props.inverse?'0px 0px 5px 0px rgba(0,0,0,0.75)':'0px 0px 5px 0px rgba(0,0,0,0.75)'} ;
    box-shadow: ${props=>!props.inverse?'0px 0px 5px 0px rgba(0,0,0,0.75)':'0px 0px 5px 0px rgba(0,0,0,0.75)'} ; 
    @media (min-width: 768px){
    height: 53vh;
    width: 50%;
    }
`
const Title = styled.h2`
    font-size: 5.7rem;
    text-transform: capitalize;
    font-weight: bold;
    line-height: 6rem;
    padding-left: 7%;
`
const Content = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap:2.5rem;
    padding-left: 2rem;
    font-weight: 500;
    @media (min-width: 768px){
    width: 50%;
    padding-left: 0;
    }
`
const Subtitle = styled.h6`
    font-size: 4rem;
    font-weight: bold;
    color:#27AAE1;
    @media (min-width: 768px){
    font-size: 3.2rem;
    } 
`
const Paragraph = styled.p`
    font-size: 3rem;
    @media (min-width: 768px){
    font-size: 2rem;
    }
`
const UlElement = styled.ul`
    font-size: 3rem;
    padding-left: 4rem;
    @media (min-width: 768px){
    font-size: 2rem;
    }
`
const LiElement = styled.li`
    
`
const ButtonLink = styled(Link)`
    cursor: pointer;
    transition: 0.3s ease-in;
    text-decoration: none;
    line-height: initial !important;
    border-radius: 8px ;
    font-size: 3rem ;
    font-weight: 500;
    padding: 13px 25px;
    text-transform: none;
    background-color: transparent;
    border: 1px solid #27AAE1 ;
    color:#27AAE1 ;
    &:hover {
    background-color:#27AAE1 ;
    color:#ffffff;
    border: 1px solid #27AAE1 ;
    transition: 0.3s ease-in;
    }
    @media (min-width: 768px){
    font-size: 2rem;
    }
`
const Button =styled.button`
    cursor: pointer;
    transition: 0.3s ease-in;
    text-decoration: none;
    line-height: initial !important;
    border-radius: 8px ;
    font-size: 3rem ;
    font-weight: 500;
    padding: 13px 25px;
    text-transform: none;
    background-color: transparent;
    border: 1px solid #27AAE1 ;
    color:#27AAE1 ;
    &:hover {
    background-color:#27AAE1 ;
    color:#ffffff;
    border: 1px solid #27AAE1 ;
    transition: 0.3s ease-in;
    }
    @media (min-width: 768px){
    font-size: 2rem;
    }
`
const ParagraphsContainer =styled.div`
    
`