import React from 'react'
import FlipperingCards2D from '../../components/Cards/FlipperingCards2D'
import { Container, SubTitle, Title, BigParagraph,ContentHeader, Background } from '../Generic-Components/GenericStyledComponents'


const OurServicesFlipCards = () => {
  return (
    
      <Background>
        <Container>
                <ContentHeader>
                    <SubTitle>Services</SubTitle>
                    <Title>Service we provide</Title>
                    <BigParagraph>Whether your business is marketing, healthcare, retail, or even dairy, we have the experience you need to bring your idea to life. We have developed a highly trained eye to predict and fully understand our clients' needs so that we can find the right solutions every time, in each area of ​​development  </BigParagraph>
                </ContentHeader>
        </Container>
        <FlipperingCards2D/>
      </Background>
  )
}

export default OurServicesFlipCards

  

