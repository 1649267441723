import React from 'react'
import styled from 'styled-components'
import { GoFlame } from 'react-icons/go'
import { AiTwotoneCheckCircle } from 'react-icons/ai'
import { Container, Background, Section } from '../Generic-Components/GenericStyledComponents'

const OurPerks = () => {
  return (
    <Section>
      <Background>
        <Container>

    <GridContainer3x3>
    <BigElement>
      <ImageContainer>
        <GoFlame/>
      </ImageContainer>
      <SubTitle className='secondary'>Our Perks</SubTitle>
      <Title className='secondary'>Remote work.</Title>
      <Title className='secondary'>Flexible hours.</Title>
      <Title className='secondary'>Learn and Grow.</Title>
    </BigElement>
    <Elem1>
      <SmallTitle>Remote Work & Flexible Hours</SmallTitle>
      <Paragraph>We're dedicated to building a remote, async company. You can work from home or beach or from anywhere where you can find a decent internet connection!</Paragraph>
    </Elem1>
    <Elem2>
      <SmallTitle>Enjoy your Holidays</SmallTitle>
      <Paragraph>We provide a paid 3-week break across the Christmas period so you can relax with your family or visit a new place.</Paragraph>
    </Elem2>
    <Elem3>
      <SmallTitle>Paid leave</SmallTitle>
      <Paragraph>We provide 2 weeks of paid leave during the year for holidays, and unlimited time when needed</Paragraph>
    </Elem3>
    <Elem4>
      <SmallTitle>Growth Budget</SmallTitle>
      <Paragraph>Spend up to 500$/year on us to grow your skills.</Paragraph>
    </Elem4>
    <Elem5>
      <SmallTitle>Yearly Bonus</SmallTitle>
      <Paragraph>Participate in Clean Commit's success, at the end of the year you'll receive a bonus depending on company financial results.</Paragraph>
    </Elem5>
    <Elem6>
      <ImageContainer className='small'>
        <AiTwotoneCheckCircle/>
        <AiTwotoneCheckCircle/>
        <AiTwotoneCheckCircle/>
      </ImageContainer>
    </Elem6>
   </GridContainer3x3>
        </Container>
      </Background>
    </Section>
  )
}

export default OurPerks


const GridContainer3x3= styled.div`
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows:34% 17% 14% 10% 8% 15%;
  grid-template-areas:
  "bigElement"
  "elem1"
  "elem2"
  "elem3"
  "elem4"
  "elem5"
  "elem6"
   ;
  @media (min-width: 768px){
    grid-template-columns: 40% repeat(2,30%);
    grid-template-rows: repeat(3,33.3%);
    grid-template-areas: 
    "bigElement  elem1 elem2"
    "bigElement  elem3 elem4"
    "bigElement  elem5 elem6 "
    ;
   }
`
const BigElement = styled.div`
  grid-area: bigElement;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  @media (min-width: 768px){
    padding-left: 10rem;
    text-align: left;
    align-items: flex-start;
  }
`
const Elem1 = styled.div`
  padding: 2rem;
  grid-area: elem1;
`
const Elem2 = styled.div`
  padding: 2rem;
  grid-area: elem2;
`
const Elem3 = styled.div`
  padding: 2rem;
  grid-area: elem3;
`
const Elem4 = styled.div`
  padding: 2rem;
  grid-area: elem4;
`
const Elem5 = styled.div`
  padding: 2rem;
  grid-area: elem5;
`
const Elem6 = styled.div`
  padding: 2rem;
  grid-area: elem6;
`

const SmallTitle = styled.h3`
  font-size: 2.7rem;
  @media (min-width:768px){
  font-size: 1.7rem;
  }
`

const Paragraph = styled.p`
  font-size: 2.4rem;
  @media (min-width:768px){
  font-size: 1.5rem;
  }
`
const SubTitle = styled.h6`
    font-size: 3rem;
    font-weight: 600;
    text-transform: uppercase;
    word-spacing:1rem;
    letter-spacing:.4rem;
    color:#27AAE1;
    &.secondary{
    font-size: 3rem;
    @media (min-width:768px){
    font-size: 1.5rem;
    }
    }
    @media (min-width:768px){
    font-size: 1.8rem;
    }
`
const Title = styled.h2`
    width: 70%;
    font-size: 4.5rem;
    font-weight bold;
    text-transform: capitalize;
    @media (min-width:768px){
    font-size: 3.7rem;
    }
    &.secondary{
    font-size: 4.2rem;
    @media (min-width:768px){
    font-size: 3.4rem;
    }
    } 
`
 const ImageContainer = styled.div`
  font-size: 15rem;
  &.small{
    font-size:2rem;
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    gap:1.5rem;
    color: #27AAE1;
  }
 `