import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import './Slide.css'

const Slider = () => {
  return (
    <>
        <Slide>
           
                <ContentImageContainer>
                    <TitleImage>
                        Explore the Tech World with 
                        <Blue>OneX</Blue>
                    </TitleImage>
                    <Paragraph>
                    Check your next destination 
                    </Paragraph>
                    <ButtonLink to='/careers'>Explore roles here</ButtonLink>
                </ContentImageContainer>
  
        </Slide>
    </>
  )
}

export default Slider

const Slide = styled.div`
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  width: 100%;
  height: 70vh;
  animation: slide 40s infinite;
  @media (min-width:768px){
   height: 100vh;
  }
`

const ContentImageContainer = styled.div`
position: absolute;
bottom:6rem ;
left:12.5% ;
max-width:75%;
@media (min-width:768px){
  max-width:52% ;
  left:12rem ;
  bottom:4rem ;
}
`
const TitleImage = styled.h2`
font-size:6rem ;
font-weight:400 ;
font-family: 'Bitter', serif;
color:#ffffff;
@media (min-width: 768px){
font-size:6.5rem ;
}
`
const Blue = styled.span`
color:#27AAE1;
`

const Paragraph = styled.p`
font-size: 2.3rem ;
margin: 1.7rem 0 5rem 0;
color:#ffffff;
@media (min-width:768px){
   font-size: 2.5rem;
  }
`
const ButtonLink = styled(Link)`
    text-transform: uppercase;
    cursor: pointer;
    transition: 0.3s ease-in;
    text-decoration: none;
    line-height: initial !important;
    background-color: #27AAE1;
    border: 1px solid #27AAE1 ;
    border-radius: 8px ;
    color: #fff;
    font-size: 2rem ;
    padding: 13px 25px;
    box-shadow: 0px 18px 80px rgb(255 201 17 / 19%), 0px 9.1125px 34.875px rgb(255 201 17 / 13%);
    &:hover {
      background-color:transparent;
      color:#27AAE1;
      transition: 0.3s ease-in;
    }
`