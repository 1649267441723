import React, { useState } from 'react'
import styled from 'styled-components'
import { AiFillSchedule } from 'react-icons/ai'
import  { TbTruckDelivery } from 'react-icons/tb'
import { GiCycle } from 'react-icons/gi'
import Modal from '../../components/Modal/Modal'
import { modal } from './how-it-work-modal-constant'
import { Container, Title, SubTitle, ContentHeader, Background,ButtonOnClick, Paragraph, ButtonContainer, CardsContainer } from '../../components/Generic-Components/GenericStyledComponents'

const HowITWork = () => {

    const [ isOpen, setIsOpen ] = useState(false);

    return (
        <>
            
        <Background id='agile-development'>

            <Container>

                    <ContentHeader>
                        <SubTitle>Woriking Process</SubTitle>
                        <Title >How IT Works</Title>
                    </ContentHeader>
                    <CardsContainer>
                      <Card>
                        <CardFront>
                            <CardContent>
                                <IconContainer>
                                <AiFillSchedule/>
                                </IconContainer>
                                <CardTitle>Plan the Project</CardTitle>
                                <Paragraph className='txt-center'>
                                A flexible plan can be adapted to the needs of the client and establish the main objectives of the project
                                </Paragraph>
                            </CardContent>
                        </CardFront>
                        <CardBack>
                            <CardContent className='color-white'>
                            <CardTitle>Plan the Project</CardTitle>
                            <Paragraph className='txt-center'>By dividing a project into several phases, each phase can be completed, tested, modified, and launched one by one instead of waiting until the entire project is finished and rolling it out at one go.</Paragraph>
                            </CardContent>
                        </CardBack>
                      </Card>
                      <Card>
                        <CardFront>
                            <CardContent>
                                <IconContainer>
                                    <GiCycle/>
                                </IconContainer>
                                <CardTitle>Continuous Development</CardTitle>
                                <Paragraph className='txt-center'>Change is always welcome throughout the web development process</Paragraph>
                            </CardContent>
                        </CardFront>
                        <CardBack>
                            <CardContent className='color-white'>
                            <CardTitle>Continuous Development</CardTitle>
                            <Paragraph className='txt-center'>
                            This is why Agile uses sprints, which is a time allotment for a set of specific tasks and milestones defined by the customer.</Paragraph>
                            </CardContent>
                        </CardBack>
                      </Card>
                      <Card>
                        <CardFront>
                            <CardContent>
                                <IconContainer>
                                    <TbTruckDelivery/>
                                </IconContainer>
                                <CardTitle>Contiunous Delivery</CardTitle>
                                <Paragraph className='txt-center'>
                                Customer satisfaction is a top priority, and this can be achieved through early and continuous product delivery</Paragraph>
                            </CardContent>
                        </CardFront>
                        <CardBack>
                            <CardContent className='color-white'>
                            <CardTitle>Continuous Delivery</CardTitle>
                            <Paragraph className='txt-center'>
                            Customers are more satisfied when they get a functional product after each phase is completed, rather than waiting until the entire project is done</Paragraph>
                            </CardContent>
                        </CardBack>
                      </Card>
                    </CardsContainer>
                    <ButtonContainer>
                          <ButtonOnClick onClick={()=> setIsOpen(true)}>Read More</ButtonOnClick> 
                    </ButtonContainer>
            </Container>
            <Modal isOpen={isOpen} setIsOpen={setIsOpen} modal={modal} />
         </Background>
        </>
      )
    }
    
    export default HowITWork
 
 




      

    




const CardFront = styled.div`
    background: #ffffff;
    border-radius: 18px;
    height: 100%;
    backface-visibility: hidden;
    transform: perspective(500px) rotateY(0deg);
    transition:all 600ms;
    padding: 4rem;

`
const CardBack = styled.div`
  background:#27AAE1;
  height: 100%;
  position: absolute;
  top:0;
  width: 100%;
  background-size: cover;
  border-radius:18px;
  backface-visibility: hidden;
  transform: perspective(500px) rotateY(180deg);
  transition:all 600ms;
  padding: 4rem;


`
    const Card = styled.div`
      position: relative;
      height: auto;
      width: 100%;
      margin-top: 2rem;
      text-align: center;
      transition: all 500ms;
      &:hover ${CardBack} {
          transform: perspective(500px) rotateY(360deg);
        }
        &:hover ${CardFront} {
            transform: perspective(500px) rotateY(180deg);
        }
        @media (min-width: 768px){
          width: 38rem;
          height: 40rem;
      }
    `

    const CardContent = styled.div`
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;   
        gap:3rem; 
        &.color-white {
            color:#ffffff;
        }
    `
    
    const CardTitle = styled.h2`
        font-size: 4.5rem;
        font-weight: bold;
        text-align: center;
        @media (min-width:768px){
        font-size:2.3rem ;
        }

    `
       
    const IconContainer = styled.div`
        font-size: 12rem;
        padding: 3rem;
        background-color:#27AAE1;
        color:#ffffff;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        @media (min-width:768px){
        font-size: 4rem;
        }
    `

