import React from "react";
import styled from "styled-components";
import checked from "../../assets/images/check.png";
import background from "../../assets/images/workspace-woman.jpg";

const Modal = ({ modal, isOpen, setIsOpen }) => {
  const { title, paragraphs, sections, images, bigSize } = modal;

  return (
    <>
      <ModalElement className={!isOpen ? "hidden" : ""}>
        <CloseButton onClick={() => setIsOpen(false)}>&times;</CloseButton>
        <TitleContainer background={background}>
          <Title paragraphs={paragraphs} background={background}>
            {title}{" "}
          </Title>
          <Background background={background}></Background>
        </TitleContainer>
        {paragraphs && (
          <ParagraphsContainer bigParagraph>
            {paragraphs.map((p, i) => (
              <BigParagraph key={i}>{p} </BigParagraph>
            ))}{" "}
          </ParagraphsContainer>
        )}
        <Container>
          {sections.map((s, i) => (
            <Segment key={i} subtitle={s.subtitle} paragraphs={paragraphs}>
              <Content
                bigSize={bigSize}
                subtitle={s.subtitle}
                background={background}
                imgInside={s.images}
              >
                {s.subtitle && <Subtitle>{s.subtitle}</Subtitle>}
                {s.paragraphs && (
                  <ParagraphsContainer>
                    {s.paragraphs.map((p, i) => {
                      if (p.ul) {
                        return (
                          <UlElement key={i}>
                            {p.ul.map((li, i) => (
                              <LiElement key={i} checked={checked}>
                                {li}
                              </LiElement>
                            ))}
                          </UlElement>
                        );
                      } else if (p.blue) {
                        return <Blue key={i}>{p.blue}</Blue>;
                      } else {
                        return <Paragraph key={i}>{p}</Paragraph>;
                      }
                    })}
                  </ParagraphsContainer>
                )}
              </Content>
              {s.images && (
                <ImageContainer background={background}>
                  {s.images.map((image, index) => (
                    <img src={image} alt="" key={index} />
                  ))}
                </ImageContainer>
              )}
            </Segment>
          ))}
          {!bigSize && images && (
            <MultipleImagesContainer bigSize={bigSize}>
              {images.map((image, index) => (
                <img src={image} key={index} />
              ))}
            </MultipleImagesContainer>
          )}
          <Container className="button">
            <ColorHeader
              className="aquamarine"
              onClick={() => setIsOpen(false)}
            >
              Close
            </ColorHeader>
          </Container>
        </Container>
      </ModalElement>
      <Overlay
        className={!isOpen ? "hidden" : ""}
        onClick={() => setIsOpen(false)}
      ></Overlay>
    </>
  );
};

export default Modal;

const TitleContainer = styled.div`
  position: relative;
  width: 100%;
  background-repeat: no-repeat;
  background-image: ${(props) =>
    props.background ? `url(${props.background})` : "none"};
  background-size: ${(props) => (props.background ? `cover` : "none")};
  z-index: -1;
  height: 15vh;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (min-width: 768px) {
    height: 40vh;
  }
`;
const Background = styled.div`
  height: 15vh;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 0;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;

  @media (min-width: 768px) {
    height: 40vh;
  }
`;
const Blue = styled.p`
  color: #27aae1;
  font-size: 2.3rem;
  @media (min-width: 768px) {
    font-size: 1.8rem;
  }
`;
const Content = styled.div`
  width: 100%;
  padding-left: ${(props) => (props.background ? "3rem" : "0")};
  @media (min-width: 768px) {
    width: ${(props) => (props.imgInside ? "55%" : "100%")};
  }
  padding-top: ${(props) =>
    !props.subtitle ? "3rem" : props.images ? "1rem" : "6rem"};
`;
const MultipleImagesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 5rem;
  padding-top: 2rem;
  width: 100%;
  img {
    height: 10rem;
  }
`;
const ImageContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  order: -1;
  justify-content: center;
  @media (min-width: 768px) {
    width: 40%;
    order: 2;
  }
  img {
    width: 100%;
    max-height: 60vh;
  }
`;

const ModalElement = styled.div`
  position: absolute;
  overflow-y: auto;
  height: 80vh;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 95%;
  background-color: white;
  padding: 5rem 6rem 5rem 6rem;
  border-radius: 5px;
  box-shadow: 0 3rem 5rem rgba(0, 0, 0, 0.3);
  z-index: 9999;
  &.hidden {
    display: none;
  }
  @media (min-width: 768px) {
    width: 70%;
  }
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(3px);
  z-index: 999;
  &.hidden {
    display: none;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 1.2rem;
  right: 2rem;
  font-size: 3rem;
  color: #333;
  cursor: pointer;
  border: none;
  background: none;
`;

const Title = styled.h2`
  z-index: 4;
  font-size: 3.2rem;
  width: 80%;
  margin-bottom: ${(props) => (props.paragraphs ? "3.5rem" : "0")};
  color: ${(props) => (props.background ? "#ffffff" : "#27AAE1")};
  font-weight: bold;
  text-transform: capitalize;
  text-align: ${(props) => (props.background ? "center" : "left")};
  @media (min-width: 768px) {
    font-size: 6rem;
  }
`;

const Subtitle = styled.h6`
  font-size: 2.7rem;
  text-transform: capitalize;
  margin-bottom: 2rem;
  @media (min-width: 768px) {
    font-size: 2.5rem;
  }
`;
const Paragraph = styled.p`
  font-size: 2.3rem;
  @media (min-width: 768px) {
    font-size: 1.8rem;
  }
`;
const UlElement = styled.ul`
  font-size: 2.5rem;
  @media (min-width: 768px) {
    font-size: 1.8rem;
  }
`;
const LiElement = styled.li`
  margin: 0;
  padding: 20px 0 20px 60px;
  list-style: none;
  background-image: ${(props) => `url(${props.checked})`};
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 35px;
`;
const BigParagraph = styled.p`
  font-size: 2.7rem;
  line-height: 3.5rem;
  color: #27aae1;
  font-weight: bold;
  @media (min-width: 768px) {
    font-size: 2rem;
    line-height: 1.5rem;
  }
`;

const Segment = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  @media (min-width: 768px) {
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
  }
`;
const Container = styled.div`
  padding-top: 2.8rem;
  position: relative;
  &.button {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }
`;
const ParagraphsContainer = styled.div`
  margin-top: ${(props) => (props.bigParagraph ? "5rem" : "0")};
`;
const ColorHeader = styled.span`
  font-size: 2rem;
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;
  background-image: linear-gradient(
    90deg,
    rgba(9, 9, 121, 1) 0%,
    #3cbfbd 55%,
    rgba(0, 212, 255, 1) 100%
  );
  background-position: 0% 100%;
  background-repeat: no-repeat;
  background-size: 0% 2px;
  transition: background-size 0.3s;
  &:hover,
  :focus {
    background-size: 100% 2px;
  }
  @media (min-width: 768px) {
    font-size: 2rem;
  }
  &.aquamarine {
    color: #3cbfbd;
  }
  &.blue {
    color: #27aae1;
  }
`;
