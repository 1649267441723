import React, { useEffect } from 'react'
import { content } from '../components/Mobile-And-Web-Dev/mobile-web-dev-constant'
import ImageHeaderContainer from '../components/Generic-Components/ImageHeaderContainer'
import bigImage from '../assets/images/developing-devs.jpg'
import smallImage from '../assets/images/software-developer.jpg'
import GenericContainer from '../components/Generic-Components/GenericContainer'

const WebMobileDev = () => {
 
  useEffect(() => {
    window.scroll(0,0);
  }, [])

    const contentHeader = {
        background: [bigImage,smallImage],
        title:["App Development -",{span:"Web"}," and ",{span:"Mobile"} ],
        buttonValue:'Contact Us'
    }

  return (
    <>
        <ImageHeaderContainer
         background={contentHeader.background}
         title={contentHeader.title}
         buttonValue={contentHeader.buttonValue}
         filter
        />
        <GenericContainer 
        content={content}
        />
    </>
  )
}

export default WebMobileDev