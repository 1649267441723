import React, { useState } from 'react'
import { HashLink as Link } from 'react-router-hash-link';
import styled from 'styled-components'
import Modal from '../Modal/Modal';
import { Container, Section } from './GenericStyledComponents';

const ComponentWithImages = ({ img, title, paragraphs, inverse, buttonText, ul, link, modal, subtitle}) => {
 
    const [ isOpen, setIsOpen ] = useState(false);
    if(window.screen.width<768) inverse=true;
 
    return (
    <Section>

    <RelativeEl>
    <Container>
        <FlexContainer>
        {inverse && <ImageContainer inverse={inverse}>
                <img src={img} alt="" />
            </ImageContainer> } 
            <Content inverse={inverse}>
                {subtitle && <Subtitle>{subtitle} </Subtitle>}
                <Title>{title} </Title>
                <ParagraphsContainer>
                    {paragraphs.map((p,i)=>(<Paragraph key={i}>{p} </Paragraph>))}
                </ParagraphsContainer>
                { ul && <UlElement>
                      {ul.map((li,i)=>(
                          <LiElement key={i}>{li }</LiElement>
                          ))}
                    </UlElement>}
                { !modal && link && <ButtonLink to={link}>{buttonText}</ButtonLink>}
                { modal && <Button onClick={()=>setIsOpen(true)}>{buttonText} </Button>}
            </Content>
            {!inverse && <ImageContainer inverse={inverse}>
                <img src={img} alt="" />
            </ImageContainer> } 
        </FlexContainer>
    </Container>
        { modal && <Modal isOpen={isOpen} setIsOpen={setIsOpen} modal={modal}/>}
    </RelativeEl>
    </Section>
  )
}

export default ComponentWithImages


const RelativeEl = styled.div`
  position: relative;
  overflow-y: initial !important;
  width: 100%;
`



const FlexContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    @media (min-width: 768px){
    align-items: center;
    flex-direction: row;
    }
`

const Content = styled.div`
    width: 100%;
    margin-top: 5rem;
    padding-left: 2rem;
    display: flex;
    flex-direction: column;
    min-height:20vh;
    justify-content: center;
    @media (min-width: 768px){
    padding-right: ${props=>props.inverse?'0':'8rem'};
    padding-left: ${props=>props.inverse?'8rem':'0'};
    width: 53%;
    margin-top: 0;
    }
`
const ImageContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    img{
        width: 100%;
        height: fit-content;
        max-height: 75vh;
        border-radius: 18px;
        @media (min-width: 768px){
        -webkit-box-shadow:${props=>props.inverse?'-4px 4px 9px 0px rgba(0,0,0,0.75)':' 4px 4px 9px 0px rgba(0,0,0,0.75)'};
        -moz-box-shadow: ${props=>props.inverse?'-4px 4px 9px 0px rgba(0,0,0,0.75)':' 4px 4px 9px 0px rgba(0,0,0,0.75)'};
        box-shadow: ${props=>props.inverse?'-4px 4px 9px 0px rgba(0,0,0,0.75)':' 4px 4px 9px 0px rgba(0,0,0,0.75)'};
        }
    }
    @media (min-width: 768px){
    width: 47%;
    }
`

const Title = styled.h2`
    font-size: 4.5rem;
    padding: 0 0 2rem 0;
    font-weight: bold;
    color:#27AAE1;
`

const ButtonLink = styled(Link)`
    cursor: pointer;
    transition: 0.3s ease-in;
    text-decoration: none;
    width: fit-content;
    line-height: initial !important;
    border-radius: 8px ;
    font-size: 3rem ;
    font-weight: 500;
    padding: 13px 25px;
    text-transform: none;
    background-color: transparent;
    border: 1px solid #27AAE1 ;
    color:#27AAE1 ;
    &:hover {
    background-color:#27AAE1 ;
    color:#ffffff;
    border: 1px solid #27AAE1 ;
    transition: 0.3s ease-in;
    }
    @media (min-width: 768px){
        font-size: 2rem;
    }
`
const Button =styled.button`
    cursor: pointer;
    transition: 0.3s ease-in;
    width: fit-content;
    text-decoration: none;
    line-height: initial !important;
    border-radius: 8px ;
    font-size: 3rem ;
    font-weight: 500;
    padding: 13px 25px;
    text-transform: none;
    background-color: transparent;
    border: 1px solid #27AAE1 ;
    color:#27AAE1 ;
    &:hover {
    background-color:#27AAE1 ;
    color:#ffffff;
    border: 1px solid #27AAE1 ;
    transition: 0.3s ease-in;
    }
    @media (min-width: 768px){
    font-size: 2rem;
    }
`
const Paragraph = styled.div`
    font-size: 3rem;
    margin-bottom: 2rem;
    @media (min-width: 768px){
    font-size: 2rem;
    }
`
const ParagraphsContainer = styled.div`
    margin-bottom: 4rem;
`

const UlElement = styled.ul`
    font-size: 3rem;
    padding: 0 0 5rem 2rem;
    @media (min-width: 768px){
    font-size: 1.7rem;
    }
`

const LiElement = styled.li`
    
`
const Subtitle = styled.div`
    font-size: 1.5rem;
    text-transform: uppercase;
    font-weight:bold;
`