import React from 'react'
import FlipCard2DHorizontal from './FlipCard2DHorizontal'
import FlipCard2DVertical from './FlipCard2DVertical'
import { routes } from './flip-cards2D-contant'
import { CardsContainer, Container } from '../Generic-Components/GenericStyledComponents'

const FlipperingCards2D = () => {
    return (
        <Container>

        <CardsContainer>
            {routes.map((card,i)=>{
                if(card.xOrY==1){      
                    return <FlipCard2DHorizontal
                     title={card.title}
                     category={card.category}
                     icon={card.icon}
                     content={card.content}
                     link={card.link}
                     key={i}
                     />
                }else{
                    return <FlipCard2DVertical
                    title={card.title}
                    category={card.category}
                    icon={card.icon}
                    content={card.content}
                    link={card.link}
                    key={i}
                    />
                }
            })}
            </CardsContainer>
        </Container>
      )
    }
    
    export default FlipperingCards2D
    
  
 