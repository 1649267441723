import backendWeb from '../../assets/images/backend-web.jpg'
import backendMobile from '../../assets/images/backend-mobile.jpg'
import jest from '../../assets/images/Jest-js-framework.jpeg'
import jasmine from '../../assets/images/jasmine.jpg'
import mocha from '../../assets/images/mocha.png'
import cypress from '../../assets/images/cypress.png'
import selenium from '../../assets/images/Selenium-3-webdriver.jpg'
import puppeteer from '../../assets/images/puppeteer.png'
import nightWatch from '../../assets/images/react-nightwatch-JS.jpg'
import karma from '../../assets/images/karma.png'

export const content = [

    {
        title:['Full-stack Engineering for every Project'],
        subtitle:'Software Craftsmanship',
        paragraphs:['We develop high quality, robust code to run your applications. Apps that work each and every time. Engineering software and design to match the user experience and user interface.'],
        button:'Contact Us',
        ul:false,
        link:'/contact-us',
        modal:false
    },
    {
        title:['Technology Systems Design'],
        subtitle:'Systems Architecture',
        paragraphs:['Experts in designing and architecting technology solutions - ask us how today.','From operating systems, backend and frontend, programming languages, frameworks, modules and dependencies we known them well.','Our teams have years of experience integrating and developing across the end to end stack. We can evaluate options based on cost, time to market, implementation risk, performance, scaling, and security considerations.'],
        button:'Ask How',
        ul:false,
        link:'/contact-us',
        modal:false
    },
    {
        title:['Frontend Web Development'],
        subtitle:'We build fast, flexible and attractive frontends',
        paragraphs:['Lean on us to develop your frontends.'],
        button:'Consult Us',
        ul:[
            'React.js',
            'Javascript',
            'Typescript',
            'Angular',
            'React Native',
            'Python',
            'PHP',
            'HTML5',
            'CSS',
            'SPAs',
            'PWAs'
        ],
        link:'/contact-us',
        modal:false

    },
    {
        title:['React.js','React Native'],
        subtitle:'A Modern Framework for New, Modern Development',
        paragraphs:['We develop React Apps for Web and Mobile. With extensive experience in React Apps we can create blazing fast frontends hooked into the latest database and 3rd party services to power your apps. '],
        button:'Try Us',
        ul:[
            'React Hooks',
            'Redux',
            'React Router',
            'Material UI',
            'Typescript',
            'Style Sheets',
            'Axios'
        ],
        link:'/contact-us',
        modal:false
    },   
     {
        title:['Backend Development'], 
        subtitle:'Developing Robust and Scalable Backends to Power Your Apps',
        paragraphs:['We carefully construct the backend to lift heavy, scale and last the test of time. Knowing how to match the right backend to the right specifications is vital and this comes with deep experience and research and development in technologies.'],
        button:'Learn More',
        ul:false,
        link:false,
        modal:{
            title:'Leverage our expertise and boost your project',
            images:false,
            sections:[
                {
                    subtitle:'Back-end Application Development',
                    images:[backendWeb],
                    paragraphs:["Our backend web development services include building scalable, fault-tolerant back ends for enterprise systems, complex web applications, IoT infrastructure, VoIP solutions, and web portals - just to name a few. We can also help you integrate your legacy system with third-party services and apps."]
                },
                {
                    subtitle:'Mobile App Back-end Development',
                    images:[backendMobile],
                    paragraphs:["We design back ends for enterprise mobile apps, smart devices and wearables, multimedia distributing mobile solutions, mobile messengers, social networks and more.Our back-end development team will take care of your app’s server and business logic. We will make sure all the necessary data is accurately synchronized across multiple devices and platforms by implementing a powerful back end. It will scale on demand to meet your needs, perform fast, integrate seamlessly with third-party services, and provide secure and reliable data storage."]
                }
            ]
        }
    },
    {
        title:['Backend Infrastructure', 'and Services'],
        subtitle:'We understand databases, middleware and 3rd party services / APIs.',
        paragraphs:false,
        button:'Talk Now',
        ul:[
            'Google Cloud Platform',
            'Amazon Web Services',
            'Microsoft Azure',
            'Firebase',
            'DynamoDB',
            'MongoDB',
            'Postgres',
            'MySQL',
            'NoSQL',
            'Oracle',
            'SAP Data Services',
            'Cloud Functions',
            'Lambda',
            'S3',
            'Cloud Storage',
            'And Much More'
        ],
        link:'/contact-us',
        modal:false
    },
    {
        title:['Quality Assurance and Testing'],
        subtitle:'Launch with Confidence with Strongly Tested Code',
        paragraphs:['Discover problems before your customers get to them. We run code on various platforms and staging environments to ensure your platform is road tested before your first customers touch it '],
        button:'Learn More',
        ul:false,
        link:false,
        modal:{
            title:'Avoid bugs and security problems in your apps',
            images:[jest,jasmine,puppeteer,karma,mocha,selenium,cypress,nightWatch],
            sections:[
                {
                    subtitle:'Security Testing',
                    paragraphs:["Security testing unveils the vulnerabilities of the system to ensure that the software system and application are free from any threats or risks. These tests aim to find any potential flaws and weaknesses in the software system that could lead to a loss of data, revenue, or reputation per employees or outsides of a company. "]
                },
                {
                    subtitle:'Integration Testing',
                    paragraphs:["Integration testing ensures that an entire, integrated system meets a set of requirements. It is performed in an integrated hardware and software environment to ensure that the entire system functions properly.  "]
                },
                {
                    subtitle:'Other kind of test we use',
                    paragraphs:['Accessibility Testing','End to End Testing ','Functional Testing','Performance Testing ']
                },
                {
                    subtitle:'Some Libraries We used to use'
                }
            ],
        }
    },
    {
        title:['Security and Compliance'],
        subtitle:'Security Matters',
        paragraphs:["In today's environment, it matters to have secure and robust apps. Building your apps on the cloud enables us to trust in global giants to secure critical infrastructure allowing us to focus on developing your software to ensure it meets high standards of security. "],
        button:'Contact Us',
        ul:false,
        link:'/contact-us',
        modal:false
    }
]