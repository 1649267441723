export const routes = [
    {
        name:'What We Offer',
        link:'/our-services',
        subRoutes:[
            {
             name:'Web and Mobile Development',
             link:'web-mobile-development'
            },
            {
                name:'API Development',
                link:'api-development'
            },
           {
               name:'Artificial Intelligence',
               link:'/artificial-intelligence'
           },
           {
            name:'Data Services',
            link:'#',
            subRoutes:[
                  {
                    name:'Data Analytics',
                    link:'/data-analytics'
                   },
                   {
                    name:'Data Engineering',
                    link:'/data-engineering'
                   },
                   {
                    name:'Data Consulting',
                    link:'/data-consulting'
                   }
            ]
            },
           {
            name:'Database Development',
            link:'/database-development'
           },
           {
            name:'Cloud Services',
            link:'/cloud-services'
           },
           {
               name:'Recruitment',
               link:'/recruitment'
           }
        ]
    },
    {
        name:'Know Us',
        link:'/company',
    },
    {
        name:'Careers',
        link:'/careers',

    },
    {
        name:'Contact Us',
        link:'contact-us'
    }
]