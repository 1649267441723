import React from 'react'
import styled from 'styled-components'
import { FaRegMap, FaRegClock, FaPhoneVolume} from 'react-icons/fa'
import { CardsContainer,Container } from '../Generic-Components/GenericStyledComponents'

const HereForYou = () => {
  return (
    <>
        <HeaderContainer>
            <SubTitle>Contact Us</SubTitle>
            <Title>We Are Here For You</Title>
        </HeaderContainer>
        <Container>

        <CardsContainer>
            <Card>
                <IconContainer><FaRegMap/></IconContainer>
                <CardTitle>Locations</CardTitle>
                <Paragraph>Phoenix, Arizona U.S.</Paragraph>
                <Paragraph>Guadalajara, Jalisco MX</Paragraph>
            </Card>
            <Card>
                <IconContainer><FaRegClock/></IconContainer>
                <CardTitle>Opening Hours</CardTitle>
                <Paragraph>Mon - Fri: 08:00am - 05:00pm</Paragraph>
            </Card>
            <Card >
                <IconContainer><FaPhoneVolume/></IconContainer>
                <CardTitle>Contact Directly</CardTitle>
                <Paragraph>US: +1 (980) 335-9813</Paragraph>
                <Paragraph>MX: +52 33 10662182</Paragraph>
            </Card>
        </CardsContainer>
        </Container>
    </>
  )
}

export default HereForYou


const Card = styled.div`
    border: .05px solid rgba(39,170,225,1);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-top: 4rem;
    height: 30rem;
    width: 100%;
    border-radius: 5px;
    @media (min-width: 768px){
    height: 25rem;
    width: 35rem;  
    }
`
const IconContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 6rem;
    color: rgba(39,170,225,1);
    margin-bottom: 2rem;
    @media (min-width: 768px){
        font-size: 5rem;
    }
`
const CardTitle = styled.h6`
    font-size: 3rem;
    @media (min-width: 768px){
        font-size: 2rem;   
    }
`
const Paragraph = styled.p`
     font-size: 2.8rem;
     @media (min-width: 768px){
        font-size: 2rem;   
    }
`

const SubTitle = styled.h6`
font-size: 2.2rem;
margin-bottom: 1rem;
text-transform: uppercase;
word-spacing:1rem;
letter-spacing:.4rem;
color:rgba(39,170,225,1);
@media (min-width: 768px){
        font-size: 1.8rem;   
 }
`

const Title = styled.h2`
font-size: 4rem;
font-weight bold;
text-transform: capitalize;
`
const HeaderContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 10rem;
`