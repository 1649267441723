import makePredictions from '../../assets/images/make-predictions.jpg'
import analize from '../../assets/images/analize-data.jpg'

export const content = [
    {
        title:'Surface Insights and Make Predictions',
        paragraphs:['We mobilise your data bringing it into a central location.',"We develop visualisations helping you analyse and predict insights into data sources you didn't know could be surfaced",'Your Data - Working for You.'],
        buttonText:'Get In Touch',
        img:makePredictions
    },
    {
        title:'Report Real-time Analytics On Key Metrics',
        paragraphs:['We work behind the scenes to automate data flow from disparate sources.','We make reporting and visualisations effortless.','Clean, Datasets - Ready For Use.'],
        buttonText:'Get In Touch',
        img:analize
    }
]