import React from 'react'
import GenericComponent from './GenericComponent'

const GenericContainer = ({ content }) => {
  return (
    <>

      { content.map((c, index)=>(
        <GenericComponent
        title={c.title}
        subtitle={c.subtitle}
        paragraphs={c.paragraphs}
        ul={c.ul?c.ul:false}
        buttonText={c.button}
        link={c.link?c.link:'/contact-us'}
        inverse={index%2==0?true:false}
        key={index}
        modal={c.modal}
        />
      ))}
    </>
  )
}

export default GenericContainer

