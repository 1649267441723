import React, { useEffect } from 'react'
import bigImage from '../assets/images/blue-red.jpg'
import smallImage from '../assets/images/blue-red-small.jpg'
import ImageHeaderContainer from '../components/Generic-Components/ImageHeaderContainer'
import AllServicesContainer from '../components/Our-Services/AllServicesContainer'


const Services = () => {
  
  useEffect(() => {
    window.scroll(0,0);
  }, [])

  const contentHeader = {
    title:['Our Services'],
    paragraphs:[{line:['We design, build, and extend digital products, platforms and apps. From mobile to web, from backend to frontend, from APIs to security. Talk to us today.']}],
    buttonValue:'Contact Us',
    background: [bigImage,smallImage]
  }

  return (
    <>
     <ImageHeaderContainer 
     title={contentHeader.title}
     background={contentHeader.background}
     paragraphs={contentHeader.paragraphs}
     buttonValue={contentHeader.buttonValue}
     />
     <AllServicesContainer/>
    </>
  )
}

export default Services

