import React from 'react'
import image from '../../assets/images/about-img2.png'
import { Container, Title, Blue, BigParagraph, Paragraph, ContainerWithLine, FlexContainerTwoElem,Image2ndPlaceContainer, Content, ButtonLink, PaddingWhiteSegments } from '../../components/Generic-Components/GenericStyledComponents'

const AboutUs = ({ link }) => {
  return (
    <>
     
        <PaddingWhiteSegments>

        <Container>
                <FlexContainerTwoElem>
                    <Content>
                        <Title>Preparing for your success
                        provide best <Blue>  IT solutions. </Blue></Title>
                        <BigParagraph>We believe digital experiences have a massive impact on our lives</BigParagraph>
                        <ContainerWithLine>
                            <Paragraph>But those experiences are only as good as the technology they're built on. Most companies lack the resources or know-how to solve today’s digital challenges. </Paragraph>
                            <Paragraph>
                            At OneX, we provide companies with the software consulting they need to deliver top digital experiences and turn problems into products people love. 
                            </Paragraph>
                        </ContainerWithLine>
                        <ButtonLink to={link}>More Details</ButtonLink>
                    </Content>
                    <Image2ndPlaceContainer>
                        <img src={image} alt="" />
                    </Image2ndPlaceContainer>
                </FlexContainerTwoElem>
        </Container>
    </PaddingWhiteSegments>
    
    </>
  )
}

export default AboutUs













