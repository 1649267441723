import { configureStore } from '@reduxjs/toolkit'
import positionsSlice from './positionsSlice'
import messagesSlice from './messagesSlice'


const store = configureStore({
    reducer:{
        positions:positionsSlice,
        messages:messagesSlice
    }
})

export default store