import React, {  useEffect } from 'react'
import bigImage from '../assets/images/dna.jpg'
import smallImage from '../assets/images/dna-small.jpg'
import BestITSolutions from '../components/Company/BestITSolutions'
import ImageHeaderContainer from '../components/Generic-Components/ImageHeaderContainer'
import OurWork from '../components/Company/OurWork'
import Extras from '../components/Company/Extras'
import OurValues from '../components/Careers/OurValues'

const Company = () => {

  useEffect(() => {
    window.scroll(0,0);
  }, [])

const contentHeader ={
  title:['Our',{span:'DNA'}],
  background: [bigImage,smallImage],
  paragraphs:[{line:["We are a",{span:"custom development"},"design to build shop."]},{line:["We bring",{span:"innovation"},"and great ideas to life."]},{line:["We",{span:"add value"},"to your business."]}]
}

  return (
    <>
  <ImageHeaderContainer 
  background={contentHeader.background}
  title={contentHeader.title}
  paragraphs={contentHeader.paragraphs}
  buttonValue='Get In Touch'
  multipleParagraphs
  filter
  />
    <BestITSolutions/>
    <OurWork/>
    <OurValues bigTitle/>
    <Extras/>
    </>
  )
}

export default Company

