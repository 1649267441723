import React, { useEffect } from 'react'
import ImageHeaderContainer from '../components/Generic-Components/ImageHeaderContainer'
import bigImage from '../assets/images/red-neuronal.png'
import smallImage from '../assets/images/red-neuronal-small.jpg'
import { content } from '../components/Artificial-Intelligence/artificial-intelligence-constant'
import ContainerWithImages from '../components/Generic-Components/ContainerWithImages'

const AIntelligence = () => {

  useEffect(() => {
    window.scroll(0,0);
  }, [])

  const contentHeader = {
    title:['Machine Learning and Artificial Intelligence'],
    background:[bigImage,smallImage]
  }

  const contentPage = {
   buttonText:'See How',
   title:'Transform your Organization.',
   paragraphs:['With autonomous decision making across every aspect of your business operations.']
  }

  return (
    <>
  
      <ImageHeaderContainer
      background={contentHeader.background}
      title={contentHeader.title}
      filter={true}
      />
      <ContainerWithImages
      content={content}
      title={contentPage.title}
      paragraphs={contentPage.paragraphs}
      buttonText={contentPage.buttonText}  
        />
    </>
  )
}

export default AIntelligence