import React from "react";
import styled from "styled-components";
import { HashLink as Link } from "react-router-hash-link";
import SubMenu from "./SubMenu";
const Menu = ({ route }) => {
  return (
    <SMenu>
      <MenuButton to={route.link}>{route.name}</MenuButton>
      <SubRoutesContainer>
        {route.subRoutes.map((subRoute) => {
          if(subRoute.subRoutes){
           return <SubMenu route={subRoute} key={subRoute.name}/>
          }
         return <SubRoute to={subRoute.link} key={subRoute.name}>
            {subRoute.name}
          </SubRoute>
})}
      </SubRoutesContainer>
    </SMenu>
  );
};

export default Menu;
const SubRoutesContainer = styled.div`
  background-color:#333 ;
  position: absolute;
  width: 21rem;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  left: 0;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.3s ease-in-out, opacity 0.3s ease-in-out;
`;
const SMenu = styled.div`
  position: relative;
  display: inline-block;
  &:hover ${SubRoutesContainer} {
    visibility: visible;
    opacity: 1;
    cursor: pointer;
  }
`;

const MenuButton = styled(Link)`
  color:#ffffff;
  text-decoration:none ;
  padding: 1rem;
  &:hover {
    transition: 0.5s ease;
    color: #27AAE1;
  }
`;

const SubRoute = styled(Link)`
  text-decoration: none;
  color: #ffffff;
  padding: 1rem;
  transition: 0.3s ease-in;
  &:hover {
    transition: 0.3s ease-in;
    color: #27AAE1;
  }
`;