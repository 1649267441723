import React, { useEffect } from 'react'
import ImageHeaderContainer from '../components/Generic-Components/ImageHeaderContainer'
import bigImage from '../assets/images/data-engineers.jpg'
import smallImage from '../assets/images/data-engineers-small.jpg'
import { content } from '../components/Data-Engineering/data-engineering-constant'
import ContainerWithImages from '../components/Generic-Components/ContainerWithImages'

const DataEngineering = () => {

  useEffect(() => {
    window.scroll(0,0);
  }, [])

   const contentHeader={
      title:['Data Engineering'],
      background: [bigImage,smallImage]
   }

   const contentPage = {
    title:'A Trusted Data Partner',
    paragraphs:['Data lives in many forms and in many places. Learn how we can bring it together to unlock your insights']
   }

  return (
    <>
      <ImageHeaderContainer
      background={contentHeader.background}
      title={contentHeader.title}
      filter={true}
      />
      <ContainerWithImages
      title={contentPage.title}
      paragraphs={contentPage.paragraphs}
      content={content}
      />
    </>
  )
}

export default DataEngineering