import dataPlan from '../../assets/images/data-plan.jpg'

export const content = [
    {
        title:['Choosing The', 'Right Data Strategy'],
        subtitle:false,
        paragraphs:["The right data strategy can depend on your organisation size, number of data sources, people and resources."],
        button:'Learn More',
        modal:{
            title:"What Is a Data Strategy?",
            sections:[
                {
                    images:[dataPlan],
                    paragraphs:["What is an enterprise data strategy? A data strategy is a vision for how a company will collect, store, manage, share and use data. The MIT CISR Data Board provides the following data strategy definition: “a central, integrated concept that articulates how data will enable and inspire business strategy.” A company’s data strategy sets the foundation for everything it does related to data.","Every organization’s data strategy will look a bit different, but generally, a data strategy will do the following:",
                    {ul:[
                        "Define how data will help the company meet business goals",
                        "Lay out how the company will complete the desired data activities to achieve its objectives",
                        "Describe the changes the organization needs to make to maximize the value of its data activities and outlines plans for how the company will make those changes",
                        "Establish a timeline for completing the proposed activities, define milestones and priorities and describe a strategy for moving forward",
                        "Discuss the financial justification of the suggested data activities and how the company will benefit from them, and use insights to increase its profits and monetize its data"
                    ]},
                    "A data strategy should be specific and actionable, but you should also be able to adjust it as circumstances change."]
                }
            ]
        }
    },
    {
        title:['Start on the', 'Right Path'],
        subtitle:"Understanding Your strategies, objectives and technologies",
        paragraphs:["Developing data insights is a step by step process requiring a holistic understanding of your source systems, objectives and strategic goals.","Once a picture of your systems has been established we can help align your data goals and requirements with your business strategy factoring in cost, time and efficiency.","Over the longer term, understanding how data can be pivotal and important process in your organisations success can help set you up for better wins in the the future."],
        button:'Chat Today'
    }
]