
import googleCloud from '../../assets/images/google-cloud.jpg'
import amazon from '../../assets/images/aws-logo.png'
import azure from '../../assets/images/microsoft-azure.png'


export const content = [
    {
        title:'Google Cloud',
        paragraphs:['Build, run, and operate cloud-native apps with Google Cloud. Embrace modern approaches like serverless, microservices, and containers. Quickly code, build, deploy, and manage without compromising security or quality.','Hosting: Compute Engine, Firebase Hosting, App Engine. APIs: Cloud Functions & Endpoints, Apigee API Mgmt','Database: Firestore, Bigtable, CloudSQL','Data Warehousing: BigQuery. Identity: Firebase Auth','Logs: Cloud Logging, Trace, Monitoring. AI/ML: Speech-to-Text, Vision AI, Text-to-Speech, Cloud Natural Language, AutoML, AI Platform'],
        img:googleCloud
    },
    {
        title:'Amazon Web Services',
        paragraphs:["Whether you're looking for compute power, database storage, content delivery, or other functionality, AWS has the services to help you build sophisticated applications with increased flexibility, scalability and reliability","Hosting: AWS Amplify, EC2. APIs: API Gateway, Lambda","Database: DynamoDB, RDS. Storage: S3","Data Warehousing: Redshift. Identity: Cognito","Logs: Cloud Watch. AI/ML: Rekognition, Textract"],
        img:amazon
    },
    {
        title:'Microsoft Azure',
        paragraphs:["Whether you're looking for compute power, database storage, content delivery, or other functionality, Azure has the services to help you build sophisticated applications with increased flexibility, scalability and reliability"],
        img:azure
    }

]