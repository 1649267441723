import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

const initialState={
    loadding:false,
    message:[],
    error:''
}

 export const createMessage = createAsyncThunk('messages/createMessage',()=>{
    return axios.post('http://localhost:4000/messages').then(response=>response.data)
})

const messageSlice = createSlice({
    name:'messages',
    initialState,
    extraReducers:builder=>{
        builder.addCase(createMessage.pending,(state)=>{
            state.loadding = true
        })
        builder.addCase(createMessage.fulfilled,(state,action)=>{
            state.loadding = false
            state.message = action.payload
            state.error = ''
        })
        builder.addCase(createMessage.rejected,(state,action)=>{
            state.loadding = false
            state.message = []
            state.error = action.error.message
        })
    }
})


export default messageSlice.reducer