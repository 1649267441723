import sync from '../../assets/images/cloud-services-fancy.jpg'
import ssl from '../../assets/images/ssl-connection.jpg'
import database from '../../assets/images/cloud-services.jpg'
import cloudSync from '../../assets/images/cloud-sync.jpg'
import cloudFunctions from '../../assets/images/cloud-functions.jpg'
import fileSync from '../../assets/images/file-sync.jpg'
import fileSystems from '../../assets/images/file-systems.jpg'
import versionControl from '../../assets/images/version-control.jpg'
import backup from '../../assets/images/backup.jpg'
import aws from '../../assets/images/aws-logo.png'

export const content = [
    {
        title:'Sync Data In Real-Time or On Schedule',
        paragraphs:['Sync data from a multitude of systems in real-time or asynchronously'],
        buttonText:'See How',
        ul:false,
        img:sync,
        modal:{
            title:'Let Us Know About Your Case',
            images:false,
            paragraphs:["There are a few types of data synchronization methods.","Let OneX set your particular right path."],
            sections:[
                {
                    subtitle:'File Synchronization:',
                    images:[fileSync],
                    paragraphs:['Faster and more error-proof than a manual copy technique, this method is most used for home backups, external hard drives, or updating portable data via flash drive. File synchronization ensures that two or more locations share the same data, occurs automatically, and prevents duplication of identical files']
                },
                {
                    subtitle:'Version Control',
                    images:[versionControl],
                    paragraphs:["This technique aims to provide synchronizing solutions for files that can be altered by more than one user at the same time. "]
                },
                {
                    subtitle:'Distributed File Systems',
                    images:[fileSystems],
                    paragraphs:["When multiple file versions must be synced at the same time on different devices, those devices must always be connected for the distributed file system to work. A few of these systems allow devices to disconnect for short periods of time, as long as data reconciliation is implemented before synchronization."]
                },
                {
                    subtitle:"Mirror Computing",
                    images:[backup],
                    paragraphs:["Mirror computing is used to provide different sources with an exact copy of a data set. Especially useful for backup, mirror computing provides an exact copy to just one other location — source to target."]
                }
            ]
        }
    },
    {
        title:'HTTPS Endpoints',
        paragraphs:['Stand-up secure endpoints for delivering and receiving 3rd party data communications with secure protocols.'],
        buttonText:'Explore Options',
        ul:false,
        img:ssl
    },
    {
        title:'Ingest and Write Data from APIs to Databases',
        paragraphs:['Setup background processes to programmatically ingest and store data.'],
        buttonText:'Get Started',
        ul:false,
        img:database
    },
    {
        title:'Run Scheduled Data Synchronisation',
        paragraphs:['Run data jobs and store it on a scheduled basis at regular intervals via cron jobs.'],
        buttonText:'Run Today',
        ul:false,
        img:cloudSync
    },
    {
        title:'GCP Cloud Functions',
        paragraphs:['Cloud Functions allows you to trigger your code on demand via your frontend, on events, from your backend and via 3rd party events.','Callable directly from any web or mobile app or backend application if approved and via HTTPS.','Why Cloud Functions.'],
        buttonText:'Run Today',
        ul:[
            'Fast, simple deployment',
            'Easily extendable',
            'Role based security and permissions'
        ],
        img:cloudFunctions
    },
    {
        title:'AWS Lambda',
        paragraphs:['AWS Lambda is a compute service that lets you run code without provisioning or managing servers.','Why Lambda?'],
        buttonText:'Run Today',
        ul:[
            'Serverless microservices architecture',
            'Modular and decoupled',
            'Security and Scalability'
        ],
        img:aws
    }
]