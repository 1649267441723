
import benefit from '../../assets/images/workers-pointing-to-the-increase-in-sales.jpg'
import robot from '../../assets/images/robot.jpg'
import machine from '../../assets/images/machine-learning.jpg'
import marketing from '../../assets/images/effective-marketing.jpg'
import customer from '../../assets/images/customer-retention.jpg'
import sales from '../../assets/images/sales-process.jpg'
import automation from '../../assets/images/automation.jpg'


export const content = [

    {
        title:'Understand How Your Business Can Benefit',
        paragraphs:['From detecting images to voice and facial recognition - predicting supply chain failures, stock levels - making sales, automating tasks to customer support and much more. AI is pervasive and available to any organisation today. We discuss how to solve AI and ML problems. Talk to us about them today.'],
        img:benefit,
        buttonText:'Learn More',
        modal:{
            title:"The Best Way To Improve Your Business",
            paragraphs:["Using AI in your business is not a luxury, it is an advantage ..."],
            images:false,
            sections:[
                {
                    subtitle:"More targeted marketing and advertising.",
                    images:[marketing],
                    paragraphs:["Advances in AI and machine learning have enabled deep personalization techniques to customize content by user. By analyzing big data from purchase histories and other customer interactions, you can zero in on what your customers really want and deliver the message that will most resonate."]
                },
                {
                    subtitle:"Increased customer retention.",
                    images:[customer],
                    paragraphs:["Delivering targeted marketing and advertising messages personalized for their customers can increase retention."]
                },
                {
                    subtitle:"Seamless automation.",
                    images:[automation],
                    paragraphs:["AI can play a big role in helping you automate the repetitive tasks that keep your online store functioning. With AI, you can automate things like product recommendations, loyalty discounts, low-level support, and more."]
                },
                {
                    subtitle:"Efficient sales process.",
                    images:[sales],
                    paragraphs:["Using AI can help you create a more efficient sales process by gathering data about your customers, automate follow-up abandoned cart inquiries, and more."]
                }
            ]
        }
    },
    {
        title:'Artificial Intelligence',
        paragraphs:['Artificial intelligence, is intelligence demonstrated by machines, unlike the natural intelligence displayed by humans and animals.','Make lifelike predictions, comprehend speech, analyse images, traverse gigantic datasets and find pertinent information.','We can help you build models that can be trained and developed to act much like the human brain, only and probably much better!'],
        img:robot,
        buttonText:'Contact Us'
    },
    {
        title:'Machine Learning',
        paragraphs:['Machine learning is an application of artificial intelligence (AI) that provides systems the ability to automatically learn and improve from experience without being explicitly programmed.','Machine learning focuses on the development of computer programs that can access data and use it learn for themselves.','Incorporate Machine Learning Models such as Naive Bayes, Random Forest and others into your applications.'],
        img:machine,
        buttonText:'Contact Us'
    }
]