 import diapo1 from '../../assets/images/banner-planning.jpg'
 import diapo2 from '../../assets/images/banner-programming.jpg'
 import diapo5 from '../../assets/images/banner-man-business.png'
 import diapo6 from '../../assets/images/banner-business.jpg'
 

 
 export const slides = [
    {
      city: 'Welcome to OneX',
      country: 'We are a creative agency for your business',
      img:diapo5,
      bg:1
    },
    
    {
      city: 'Total IT solution',
      country: 'Best IT solution agency for your business',
      img: diapo6,
      bg:1
    },
    
    {
      city: 'Solution for all IT Security',
      country: 'Whether bringing new and amazing products and an services to market',
      img: diapo2,
      bg:2
    },
    
    {
      city: 'Best Experience Engineer',
      country: 'Whether bringing new and amazing products and an services to market',
      img: diapo1,
      bg:2
    },
    
];
    
    
    
  