import React from 'react'
import image from '../../assets/images/outsourcing.jpg'
import { Container, Title, SubTitle, ContentHeader, BigParagraph, Content, FlexContainerTwoElem, Image2ndPlaceContainer, ButtonLink, Paragraph, ContainerWithLine, PaddingWhiteSegments } from '../Generic-Components/GenericStyledComponents'

const Outsourcing = () => {
    return (
          <PaddingWhiteSegments>

            <Container>
                    <ContentHeader>
                        <SubTitle>Recruitment & Outsourcing</SubTitle>
                        <Title>Hire Best Talent</Title>
                        <BigParagraph>With a dedicated Account Manager to help you formulate a market leading recruitment strategy, <b>less than a third of your normal recruitment cost </b> AND a flexible smorgasbord of innovative solutions to pick from. <br/>Now you can have all this… and more.</BigParagraph> 
                    </ContentHeader>
                    <FlexContainerTwoElem>
                        <Content>
                          <ContainerWithLine>
                            <Paragraph>
                            We can work in partnership with your Talent Acquisition Team or we can BE your Talent Acquisition Team. You decide. You set your expected volume of recruitment and we sit down with you to design a solution that works for your business.
                            </Paragraph>
                            <Paragraph>
                            Your business is unique and the solution that works for you will be just as unique. We design a solution that incorporates not only high quality recruitment but also as many of the following elements as you want. You pick which ones you want and we bring them to market for you, its as simple as that.
                            </Paragraph>
                          </ContainerWithLine>
                            <ButtonLink to='/recruitment'>Read More</ButtonLink>
                        </Content>
                        <Image2ndPlaceContainer>
                            <img src={image} alt="" />
                        </Image2ndPlaceContainer>
                    </FlexContainerTwoElem>
            </Container>
          </PaddingWhiteSegments>
      )
    }
    
    export default Outsourcing
    




