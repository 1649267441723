import { IoLocationSharp } from "react-icons/io5";
import { FaPhoneAlt, FaEnvelope, FaGlobe } from "react-icons/fa";

export const content = [
  {
    title: "Companies",
    ul: [
      {
        li: "About",
        link: "/company",
      },
    ],
  },
  {
    title: "Careers",
    ul: [
      {
        li: "Job opportunities",
        link: "/careers",
      },
    ],
  },
  {
    title: "Contact",
    ul: [
      {
        icon: <IoLocationSharp />,
        li: "Phoenix, Arizona U.S.",
        link: "#",
      },
      {
        icon: <FaPhoneAlt />,
        li: "+1 (980) 335-9813",
        link: "#",
      },
      {
        icon: <IoLocationSharp />,
        li: "Guadalajara, Jalisco MX",
        link: "#",
      },
      {
        icon: <FaPhoneAlt />,
        li: "+52 33 10662182",
        link: "#",
      },
      {
        icon: <FaGlobe />,
        li: "contact@onexdigitalservices.com",
        link: "#",
      },
    ],
  },
];
