import { GiTechnoHeart, GiTeamIdea, GiFireSilhouette } from 'react-icons/gi' 
import { FaThumbsUp } from 'react-icons/fa'
import { BiUserPin } from 'react-icons/bi'
import { RiTeamFill } from 'react-icons/ri'

export const cards = [
    {
        icon: <GiTechnoHeart/>,
        title:'Passion',
        content:'Question the status quo. Make a difference to humanity through intelligence.'
    },
    {
        icon:<FaThumbsUp/>,
        title:'Reliability',
        content:'Back solutions with deep knowledge of business and technology domains. Be respected for credibility, reliability and certainty.'
    },
    {
        icon:<BiUserPin/>,
        title:'Customer Centricity ',
        content:'Place the customer in the core of everything you do. Delighting the customer is a way of life.'
    },
    {
        icon:<GiTeamIdea/>,
        title:'Continuous Learning ',
        content:'Success depends on the speed and continuity of learning. Stay relevant and ahead of the game. Learn, always.'
    },
    {
        icon:<GiFireSilhouette/>,
        title:'Adaptability ',
        content:'Agility is not a choice. Don’t just adapt to change, be an agent of change. Set new rules and blaze trails.'
    },
    {
        icon:<RiTeamFill/>,
        title:'Teamwork',
        content:'Collaborate across diverse teams. True team-work is involving diverse people in the ecosystem who may or may not be directly connected with your work.'
    },
]