import React from 'react'
import styled from 'styled-components'
import { cards } from './our-values-flip-cards-constant'
import FlipCard2DHorizontal from '../../components/Cards/FlipCard2DHorizontal'
import { Container, Section, CardsContainer } from '../Generic-Components/GenericStyledComponents'

const OurValues = ({bigTitle}) => {
  return (
    <Section>

    <Container>  
    <FlexContainer>
       <Title bigTitle={bigTitle} >Our <Blue>Values</Blue></Title>
       <CardsContainer>

        {cards.map((elem,ind)=>(
            <FlipCard2DHorizontal
            icon={elem.icon}
            title={elem.title}
            content={elem.content}
            fromValues
            />
            ))}
         </CardsContainer>
    </FlexContainer>
   </Container>
 </Section>
  )
}

export default OurValues

const Title = styled.h2`
    font-size: 4.5rem;
    font-weight: bold;
    text-transform: capitalize;
    @media (min-width:768px){
    font-size: ${props=>props.bigTitle?"4.5rem":"3.7rem"};
    }
`
const Blue = styled.span`
    color: #27AAE1;
`
const FlexContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap:3rem;
`




