import React, { useEffect } from 'react'
import { content } from '../components/Data-Analytics/data-analytics-constant'
import ImageHeaderContainer from '../components/Generic-Components/ImageHeaderContainer'
import GenericContainer from '../components/Generic-Components/GenericContainer'
import bigImage from '../assets/images/chart-analysis.jpg'
import smallImage from '../assets/images/chart-analysis-small.jpg'

const DataAnalytics = () => {

  useEffect(() => {
    window.scroll(0,0);
  }, [])

  const contentHeader={
    title:['Data Analytics'],
    background:[bigImage,smallImage]
  }

  return (
    <>
      <ImageHeaderContainer
      background={contentHeader.background}
      title={contentHeader.title}
      filter={true}
      />
      <GenericContainer
      content={content}
      />
    </>
  )
}

export default DataAnalytics