import React, { useState } from "react";
import styled from "styled-components";
import { HashLink as Link } from "react-router-hash-link";
import { FaBars } from "react-icons/fa";
import {
  TiSocialFacebook,
  TiSocialLinkedin,
  /*
  TiSocialTwitter,
  TiSocialInstagram,
  */
} from "react-icons/ti";
import Menu from "./Menu";
import { FACEBOOK_URL, LINKEDIN_URL } from "../../constants";
import logo_1 from "../../assets/images/logo_1.png";

const Navbar = ({ toggleDrawer, routes }) => {
  const [scroll, setScroll] = useState(false);

  const handleScroll = () => {
    if (window.scrollY >= 120) {
      setScroll(true);
    } else {
      setScroll(false);
    }
  };

  window.addEventListener("scroll", handleScroll);

  return (
    <SNavbar className={scroll && "change-background"}>
      <NavContainer>
        <DrawerButton onClick={toggleDrawer}>
          <FaBars />
        </DrawerButton>
        <SNavbarBrand to="/" scroll={scroll}>
          <img src={logo_1} alt="logo" />
        </SNavbarBrand>
        <RightNav>
          <NavRoutes>
            {routes.map((route) => {
              if (route.subRoutes) {
                return <Menu route={route} key={route.name} />;
              }
              return (
                <NavRoute to={route.link} key={route.name}>
                  {route.name}
                </NavRoute>
              );
            })}
          </NavRoutes>
          <SocialNav>
            <SocialMed target="_blank" href={FACEBOOK_URL}>
              <TiSocialFacebook />
            </SocialMed>
            <SocialMed target="_blank" href={LINKEDIN_URL}>
              <TiSocialLinkedin />
            </SocialMed>
            {/*
            <SocialMed  target='_blank'href="https://twitter.com">
              <TiSocialTwitter/>
            </SocialMed>
            <SocialMed  target='_blank'href="https://instagram.com">
              <TiSocialInstagram/>
            </SocialMed>
            */}
          </SocialNav>
        </RightNav>
      </NavContainer>
    </SNavbar>
  );
};

export default Navbar;

const DrawerButton = styled.button`
  all: unset;
  font-size: 3rem;
  display: grid;
  @media (min-width: 768px) {
    display: none;
  }
`;

const SNavbar = styled.nav`
  background-color: transparent;
  position: fixed;
  top: 0;
  z-index: 200;
  width: 100%;
  &.change-background {
    background: rgba(0, 0, 0, 0.7);
  }
`;
const NavContainer = styled.div`
  padding: 1rem 0;
  height: 10rem;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  width: 88%;
  @media (min-width: 576px) {
    width: 540px;
  }
  @media (min-width: 768px) {
    width: 720px;
  }
  @media (min-width: 992px) {
    width: 960px;
  }
  @media (min-width: 1200px) {
    width: 1140px;
  }
  @media (min-width: 1400px) {
    width: 1320px;
  }
`;
const SNavbarBrand = styled(Link)`
  text-decoration: none;
  color: #ffffff;
  transition: height 1s ease;
  padding-top: ${(props) => (props.scroll ? "0" : "10rem")};
  img {
    height: ${(props) => (props.scroll ? "7rem" : "15rem")};
  }
`;
const RightNav = styled.div`
  display: flex;
  gap: 2rem;
`;
const NavRoutes = styled.div`
  @media (max-width: 768px) {
    display: none;
  }
  display: flex;
  gap: 2.5rem;
  font-size: 1.7rem;
  align-items: center;
`;
const NavRoute = styled(Link)`
  text-decoration: none;
  color: white;
  padding: 0.5rem;
  transition: 0.5s ease;
  &:hover {
    color: #27aae1;
  }
`;

const SocialNav = styled.div`
  display: flex;
  align-items: center;
  gap: 1.5rem;
  @media (max-width: 768px) {
    display: none;
  }
`;
const SocialMed = styled.a`
  font-size: 2rem;
  text-decoration: none;
  color: #ffffff;
  &:hover {
    color: #27aae1;
  }
`;
