import React, { useEffect } from 'react'
import styled from 'styled-components'
import bigImage from '../assets/images/video-img.jpg'
import smallImage from '../assets/images/video-img-small.jpg'
import { IoLocationSharp } from 'react-icons/io5'
import { FaGlobe, FaPhoneAlt } from 'react-icons/fa'
import { TiSocialFacebook,TiSocialLinkedin,TiSocialTwitter,TiSocialInstagram } from "react-icons/ti"
import { Container, Section } from '../components/Generic-Components/GenericStyledComponents'
import ImageHeaderContainer from '../components/Generic-Components/ImageHeaderContainer'
import MakeAQuote from '../components/Home/MakeAQuote'
import HereForYou from '../components/ContactUs/HereForYou'
const ContactUs = () => {

  useEffect(() => {
    window.scroll(0,0);
  }, [])
  

  const contentHeader = {
    title:["Tell Us",{span:" About Your Project"} ],
    paragraphs:[{line:[' Whether you want to discuss a project or just simply want to say hello, we would be more than happy to talk to you. OneX are always available to speak to you about your business needs and work with you to creates technological solutions.']}],
    buttonValue:"Let's Get Started",
    link:'#start-with-us',
    background: [bigImage,smallImage]
  }
  return (
    <>
    <ImageHeaderContainer
    background={contentHeader.background}
    link={contentHeader.link}
    buttonValue={contentHeader.buttonValue}
    title={contentHeader.title}
    paragraphs={contentHeader.paragraphs}
    filter
    smallText
    />
      <Section >
    <Container>
      <HereForYou/>
   {/*      <MarginB>

       <Title >Let´s Get Started</Title>
        </MarginB> */}
{/*         <GridContainer>
        <FormContainer>
          <Paragraph>Send us a message and we’ll be in touch as soon as possible.</Paragraph>
          <form>
            <Input placeholder='First Name' type="text" name="firstName" />
            <Input placeholder='Last Name' type="text" name="lastName" />
            <Input placeholder='Email' type="email" name="email" />
            <Input placeholder='Phone' type="text" name="phone" />                
            <Input placeholder='Company' type="text" name="company" />
            <TextArea name="message" placeholder='Write a message' />
            <ButtonInput value="SEND MESSAGE" type="submit"/>
          </form>
        </FormContainer>
        <InformationContainer>
          <MarginB>
          <Title>Call Us</Title>
          <Group>
          <Info><IoLocationSharp/> Phoenix, Arizona U.S.</Info>
          <Info><FaPhoneAlt/> +1 (980) 335-9813</Info>
          </Group>
          <Group>
          <Info><IoLocationSharp/> Guadalajara, Jalisco MX</Info>
          <Info><FaPhoneAlt/> +52 33 10662182</Info>
          </Group>
          </MarginB>
          <MarginB>
          <Title>Email Us</Title>
          <Info><FaGlobe/> contact@onexdigitalservices.com</Info>
          </MarginB>
          <MarginB>
          <Title>Follow Us</Title>
          <SocialNav>
            <SocialMed target='_blank' href="https://facebook.com">
              <TiSocialFacebook/>
            </SocialMed>
            <SocialMed target='_blank' href="https://linkedin.com">
              <TiSocialLinkedin/>
            </SocialMed>
            <SocialMed  target='_blank'href="https://twitter.com">
              <TiSocialTwitter/>
            </SocialMed>
            <SocialMed  target='_blank'href="https://instagram.com">
            <TiSocialInstagram/>
            </SocialMed>
          </SocialNav>
          </MarginB>
          </InformationContainer>
        </GridContainer> */}
    </Container>
      </Section>
    <MakeAQuote/>
    </>
  )
}

export default ContactUs



const Paragraph = styled.p`
font-size: 2.5rem ;
margin: 1.7rem 0 5rem 0;
color:#ffffff;
@media (min-width:768px){
  font-size: 2rem;
}
`


const Title = styled.h2`
font-size:4rem ;
font-weight:bold ;
font-family: 'Bitter', serif;
text-align:center ;
`

const GridContainer = styled.div`
display:grid ;
grid-template-rows:60% 40%;
grid-template-columns: 100%;
@media (min-width:768px){
  grid-template-columns:50% 50% ;
  grid-template-rows: 100%;
}
`
const FormContainer = styled.div`
padding:2rem ;
border: 1px solid #d0d0d0 ;
margin-top:6rem ;
background-color:#333 ;
margin:0 auto ;
`

const Input= styled.input`
width:100% ;
margin-bottom:2.5rem ;
font-size:2.5rem ;
padding-left:1rem ;
`
const TextArea = styled.textarea`
width:100% ;
margin-bottom:5rem ;
min-height: 10vh;
`
const ButtonInput = styled.input`
    text-align:center ;
    cursor: pointer;
    transition: 0.3s ease-in;
    text-decoration: none;
    border: 1px solid #27AAE1 ;
    border-radius: 8px ;
    background-color:transparent;
    color:#27AAE1;
    font-size: 2rem ;
    padding: 13px 25px;
    text-transform: none;
    &:hover {
      background-color: #27AAE1;
      color: #fff;
      transition: 0.3s ease-in;
    }
`
const InformationContainer = styled.div`
width:100% ;
margin-top:6rem ;
`
const Info = styled.p`
font-size: 2.5rem ;
color:#222;
text-align:center ;
`
const SocialNav = styled.div`
display: flex ;
justify-content:center ;
gap:1rem;
`
const SocialMed= styled.a`
font-size:3rem ;
text-decoration: none;
color:black;
`
const MarginB = styled.div`
margin-bottom:5rem;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
gap: 2rem;
`

const Group = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
`