import React, { useEffect } from 'react'
import ImageHeaderContainer from '../components/Generic-Components/ImageHeaderContainer'
import bigImage from '../assets/images/cloud-background.jpg'
import smallImage from '../assets/images/cloud-background-small.jpg'
import { content } from '../components/Cloud-Services/cloud-services-constant'
import ContainerWithImages from '../components/Generic-Components/ContainerWithImages'


const CloudServices = () => {

  useEffect(() => {
    window.scroll(0,0);
  }, [])

  const contentHeader ={
    title:['Cloud Native Development'],
    background:[bigImage,smallImage]
  }

  const contentPage = {
    buttoText:'Consult Us',
    title:'Modern cloud applications built from the ground up.',
    paragraphs:["With unparalleled reliability, scalability, speed and security there isn't another place to build and deploy applications today.",

   " We can stay code focused with the scalability and efficiency of getting started with the cloud. Scale when required. We tap into the multitude of services that cloud providers offer to ensure you're on top of the latest technologies built on the cloud. If you have older workloads that required a lift and shift into a cloud then contact us today. We are experts in IaaS, PaaS and BaaS offered by the top tier cloud providers."]
  }

  return (
    <>
    <ImageHeaderContainer 
    background={contentHeader.background}
    title={contentHeader.title}
    filter
    />
    <ContainerWithImages
    paragraphs={contentPage.paragraphs}
    title={contentPage.title}
    content={content}
    buttonText={contentPage.buttoText}
    />
    </>
  )
}

export default CloudServices