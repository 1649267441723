import React, { useEffect } from 'react'
import ImageHeaderContainer from '../components/Generic-Components/ImageHeaderContainer'
import { content } from '../components/API-Development/api-dev-constant'
import bigImage from '../assets/images/cloud-data-female.jpg'
import smallImage from '../assets/images/cloud-data-female-small.jpg'
import ContainerWithImages from '../components/Generic-Components/ContainerWithImages'

const APIDevelopment = () => {

  useEffect(() => {
    window.scroll(0,0);
  }, [])

  const contentHeader = {
    title:['API Services'],
    background:[bigImage,smallImage]
  } 

  const contentPage = {
    title:'API Development and Integration',
    paragraphs:['We can connect you with internal and external data sources securely.']
  }
  return (
    <>
    <ImageHeaderContainer
    background={contentHeader.background}
    title={contentHeader.title}
    filter
    />
    <ContainerWithImages
    content={content}
    title={contentPage.title}
    paragraphs={contentPage.paragraphs}
    />
    </>
  )
}

export default APIDevelopment