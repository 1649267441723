import React from 'react'
import styled from 'styled-components'
import { Container } from '../Generic-Components/GenericStyledComponents'

const MakeAQuote = () => {
  return (
    <>
 <Background id='start-with-us'>
        <Container>
            <HeaderContainer>
                <SubTitle>Contact Us</SubTitle>
                <Title>Feel Free Contact </Title>
                <Title>Us Now</Title>
            </HeaderContainer>
            <Form>
              <Input
              placeholder='Name'
              name='name'
              />
              <Input
              placeholder='Email'
              name='email'
              />
              <Input
              name='phone'
              placeholder='Phone'
              />
              <Input
              placeholder='Website'
              name='website'
              />
              <TextTarea
              placeholder='Your Message'
              />
              <ButtonLink>Send Message</ButtonLink>
            </Form>
        </Container>
    
  </Background>
    </>
  )
}

export default MakeAQuote

const Background = styled.div`
    background-color:#EFF2F7; 
`

const HeaderContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 10rem;
`

const SubTitle = styled.h6`
font-size: 2.2rem;
margin-bottom: 1rem;
text-transform: uppercase;
word-spacing:1rem;
letter-spacing:.4rem;
color:rgba(39,170,225,1);
@media (min-width: 768px){
        font-size: 1.8rem;   
 }
`

const Title = styled.h2`
font-size: 4rem;
font-weight bold;
text-transform: capitalize;
`

const Input = styled.input`
  width:calc(100% - 4rem);
  margin: 2rem;
  padding: 1rem;
  font-size: 2.8rem;
  border-radius: 5px;
  background-color: #FFFFFF;
  color: gray;
  font-family: bold;
  transition: 500ms ease;
  border: 1px solid transparent;
  &:focus{
    border: 1px solid transparent;
    outline: 0;
    box-shadow: 0 0 0 3px  rgba(14, 190, 239, 0.37);
  }
  &::placeholder{
    color: gainsboro;
  }
  @media (min-width: 768px){
  width:calc(50% - 4rem);
  font-size: 1.9rem;
 }
`
const TextTarea =styled.textarea`
  width: calc(100% - 4rem);
  height: 20vh;
  margin: 2rem;
  padding: 1rem;
  border-radius: 5px;
  background-color: #FFFFFF;
  transition: 500ms ease;
  color: gray;
  font-family: bold;
  font-size: 2.8rem;
  border: 1px solid transparent;
  &:focus{
    border: 1px solid transparent;
    outline: 0;
    box-shadow: 0 0 0 3px  rgba(14, 190, 239, 0.37);
  }
  &::placeholder{
    color: gainsboro;
  }
  @media (min-width:768px){
  font-size: 1.9rem;
  }
`
const ButtonLink = styled.a`
    display: block;
    margin:4rem auto 0 auto;
    cursor: pointer;
    transition: 500ms ease;
    text-decoration: none;
    width: fit-content;
    border-radius: 8px ;
    background-color:#0C5ADB;
    color:#fff;
    font-size: 2.3rem ;
    padding: 13px 25px;
    text-transform: none;
    &:hover {
      background-color:#0C5ADB;
      color:#fff;
    }
    @media (min-width:768px){
  font-size: 1.8rem;
  }
`
const Form = styled.form`
margin-top: 6rem;
padding-bottom: 6rem;
`