import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { Container, Section } from '../../components/Generic-Components/GenericStyledComponents'


const Contact = () => {
  return (
    <Section>
      <Container>
        <FlexContainer>
        <SubTitle>Join Us</SubTitle>
        <Title>Send us your resume</Title>
        <BigParagraph>Haven't found a suitable job description, but you still wana be a part of OneX ?<br/>Contact us!</BigParagraph>
        <ColorHeader className='blue' to='/contact-us'>Contact Us !</ColorHeader>
       </FlexContainer>
      </Container>
    </Section>
  )
}

export default Contact

const ColorHeader = styled(Link)`
    font-size: 4.2rem;
    text-decoration: none;
    font-weight: bold;
    background-image: linear-gradient(90deg,rgba(9,9,121,1)  0%, #3CBFBD 55%, rgba(0,212,255,1) 100%);
    background-position: 0% 100%;
    background-repeat: no-repeat;
    background-size: 0% 2px;
    transition: background-size .3s;
    &:hover, :focus {
      background-size: 100% 2px;
    }
    @media (min-width:768px){
    font-size: 3.4rem;
    }
    &.aquamarine{
      color:#3CBFBD;
      &:hover{
        color:#3CBFBD;
      }
    }
    &.blue{
    color:#27AAE1;
    &:hover{
        color:#27AAE1;
     }
    }
`
const FlexContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  gap:2rem;
`

const SubTitle = styled.h6`
    font-size: 3rem;
    font-weight: 600;
    text-transform: uppercase;
    word-spacing:1rem;
    letter-spacing:.4rem;
    color:#27AAE1;
    &.secondary{
    font-size: 3rem;
    @media (min-width:768px){
    font-size: 1.5rem;
    }
    }
    @media (min-width:768px){
    font-size: 1.8rem;
    }
`
const BigParagraph = styled.p`
    font-size: 3.3rem;
    @media (min-width:768px){
     font-size: 2.4rem;
    }
`
const Title = styled.h2`
    width: 70%;
    font-size: 4.5rem;
    font-weight bold;
    text-transform: capitalize;
    @media (min-width:768px){
    font-size: 3.7rem;
    }
    &.secondary{
    font-size: 4.2rem;
    @media (min-width:768px){
    font-size: 3.4rem;
    }
    }
    
`