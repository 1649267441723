import React from 'react'
import { IoIosLaptop,IoIosPhonePortrait, IoIosApps }  from 'react-icons/io'
import { FaAws, FaChartPie } from 'react-icons/fa'
import { SiGooglecloud } from 'react-icons/si'
import { SiMicrosoftazure } from 'react-icons/si'
import { TbApi,TbApiApp, TbDeviceDesktopAnalytics } from 'react-icons/tb' 
import { SiFastapi } from 'react-icons/si' 
import { FaDatabase, FaServer } from 'react-icons/fa'
import { VscServerProcess }  from 'react-icons/vsc'
import { FaBrain } from 'react-icons/fa'
import { SiMoleculer } from 'react-icons/si'
import { AiOutlineCode } from 'react-icons/ai'
import { BiListCheck, BiMoney } from 'react-icons/bi'
import { BsPersonCheckFill } from 'react-icons/bs'
import { GiReceiveMoney }  from 'react-icons/gi'

export const services = [

    {
        title:'Web and Mobile App Development',
        subtitle:'Developed with quality design and engineering',
        bigParagraph:'We build, test and deploy at scale.',
        paragraph:'Our expertise and deep experience in Systems Architecture & Design, Backend and Frontend Engineering allows us to create wonderful digital experiences.',
        icons:[<AiOutlineCode key={0}/>,<IoIosLaptop key={1}/>,<IoIosPhonePortrait key={2}/>],
        link:'/web-mobile-development'
    },
    {
        title:'Cloud Native Development',
        subtitle:'Shift your workloads into the cloud',
        bigParagraph:'We run apps on Amazon Web Services (AWS), Google Cloud Platform (GCP) and Microsoft Azure.',
        paragraph:"We can write cloud native code that decouples your frontend and backend. Whether we're building from scratch, extending your apps via APIs or more we have cloud in mind",
        icons:[<FaAws key={3}/>,<SiGooglecloud key={4}/>,<SiMicrosoftazure key={5}/>],
        link:'/cloud-services'
    },
    {
        title:'API development',
        subtitle:'Hook into new advanced services with',
        bigParagraph:'We develop APIs to extend and integrate your application(s) with others.',
        paragraph:'Integrate 3rd party libraries, applications, data and services into new or existing applications.',
        icons:[<TbApiApp key={6} />,<TbApi key={7} />,<SiFastapi key={8}/>],
        link:'/api-development'
    },
    {
        title:'Database and Storage',
        subtitle:'Need more space for growing needs of users?',
        bigParagraph:"From noSQL to SQL we have you covered.",
        paragraph:'We can lift and shift your database into the cloud.',
        icons:[<FaDatabase key={9}/>,<FaServer key={10}/>],
        link:'/database-development'
    },
    {
        title:'Machine Learning and Artificial Intelligence',
        subtitle:'Automate and integrate',
        bigParagraph:"We train data sets and build custom models.",
        paragraph:'Understand user intents, fulfil requests and read unstructured data.',
        icons:[<SiMoleculer key={11}/>,<FaBrain key={12}/>],
        link:'/artificial-intelligence'
    },
    {
        title:'Recruitment',
        subtitle:'Drive your business goals with',
        bigParagraph:"At OneX, our tech recruitment specialists offer you a tailored approach to finding the right hire for your business needs.",
        paragraph:'With our extensive knowledge of the IT landscape and future trends, we help you find the candidates you need to succeed.',
        icons:[<BsPersonCheckFill key={13}/>,<BiListCheck key={14}/>,<GiReceiveMoney key={15}/>],
        link:'/recruitment'
    },
    {
        title:'Data engineering',
        subtitle:'Unlock Insights Through Data',
        bigParagraph:"Turn Insights into Actions and Models into Outcomes today.",
        paragraph:'Start using your data to make real-time decisions across your organisation. The impossible is now possible.',
        icons:[<IoIosApps key={16}/>,<VscServerProcess key={17}/>],
        link:'/data-engineering'
    },
    {
        title:'Data consultancy',
        subtitle:'Drive your business goals with',
        bigParagraph:"A data strategy is a vision for how a company will collect, store, manage, share and use data.",
        paragraph:'Let OneX help you to set the right path for your business',
        icons:[<IoIosApps key={18}/>,<TbDeviceDesktopAnalytics key={19}/>],
        link:'/data-consulting'
    },
    {
        title:'Data analytics',
        subtitle:'Drive your business goals with',
        bigParagraph:"Companies strive to unify their data because, by default, most data is inaccessible.The goal of centralising data is to perform fast analytics on data that is as close to real-time as possible to drive decision making for analysts and business units down to the lowest level employee",
        paragraph:"It's time to unify your business",
        icons:[<IoIosApps key={20}/>,<FaChartPie key={21}/>],
        link:'/data-analytics'
    }
]