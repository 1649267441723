import React from 'react'
import { HashLink as Link } from 'react-router-hash-link'
import styled from 'styled-components'
import { Container, Section, Title, SubTitle } from '../Generic-Components/GenericStyledComponents'

const ComponentWImgTitleCentered = ({ subtitle, title, paragraphs,img, inverse,link,buttonText}) => {

  const widthScreen = window.screen.width;
  if(widthScreen<768){
    inverse=true;
  }

  return (
    <Section>

    <Container>
     <FlexContainer>
      <HeaderContainer>
       {subtitle && <SubTitle>{subtitle}</SubTitle>}
      <Title>{title}</Title>
      </HeaderContainer>
      <Content>
      { inverse && <ImageContainer>
        <img src={img} alt="" />
      </ImageContainer>}
      <ParagraphsContainer>
        {paragraphs.map((p,i)=>{
          if(p.ul){
            return <UlElement>
            {p.ul.map((li,ind)=>(<LiElement key={ind}>{li}</LiElement>))}
          </UlElement>
        }else{
          return <Paragraph key={i}>{p} </Paragraph>
        }})}
      { link && buttonText &&<ButtonContainer><ButtonLink to={link}>{buttonText}</ButtonLink></ButtonContainer>}
      </ParagraphsContainer>
      { !inverse && <ImageContainer>
        <img src={img} alt="" />
      </ImageContainer>}
      </Content>
     </FlexContainer>
    </Container>
    </Section>
  )
}

export default ComponentWImgTitleCentered


const FlexContainer= styled.div`
    display: flex;
    gap:3rem;
    flex-direction: column;
    align-items: center;
`
const Content =styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    margin-top: 5rem;
    @media (min-width:768px){
        flex-direction: row;
    }
`


const UlElement = styled.ul`
  font-size: 3rem;
  @media (min-width:768px){
    font-size: 2rem;   
  }
`
const LiElement = styled.li`
  
`
const Paragraph = styled.p`
font-size: 3rem ;
&.color-black {
color:#ffffff;
}
&.txt-small {
  font-size: 2rem;   
  @media (min-width:768px){
    font-size: 1.6rem;   
  }
}
@media (min-width:768px){
    font-size: 2rem;   
  }
`
const ParagraphsContainer = styled.div`
    width: 100%;
    margin-top: 3rem;
    @media (min-width:768px){
    width: 50%;
    margin-top: auto;
  }
    
`
const ImageContainer =styled.div`
   width: 100%;
   display: flex;
   align-items: center;
    img{
        width: 100%;
        border-radius: 12px;
    }
    @media (min-width:768px){
    width: 45%;
  }
`
const HeaderContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap:1rem;
    @media (min-width: 768px){
    align-items: center;
    }
`
const ButtonLink = styled(Link)`
    cursor: pointer;
    transition: 0.3s ease-in;
    text-decoration: none;
    line-height: initial !important;
    border-radius: 8px ;
    font-size: 3rem ;
    font-weight: 500;
    padding: 13px 25px;
    text-transform: none;
    background-color: transparent;
    border: 1px solid #27AAE1 ;
    color:#27AAE1 ;
    &:hover {
    background-color:#27AAE1 ;
    color:#ffffff;
    border: 1px solid #27AAE1 ;
    transition: 0.3s ease-in;
    }
    @media (min-width: 768px){
        font-size: 2rem;
    }
`

const ButtonContainer = styled.div`
  padding-top: 3rem;
`