import clientMeet from '../../assets/images/client-meet.jpg'
import techGroup from '../../assets/images/tech-group.jpg'

export const content =[
    {
        title:'Our process',
        paragraphs:["Our clients come to us with complex challenges that need solving. That’s why we take the time to fully understand their business goals, their industry and their competition to design an innovative solution to their challenges. From concept to completion of the project, we use a tried-and-tested process to get you the results you need, on time, every time. "],
        img:clientMeet,
        buttonText:'Explore Our Process',
        link:'/#agile-development'
    },
    {
        title:'Our Capabilities',
        paragraphs:["Our experienced in-house team can cater to every skill requirement, creating flawless digital solutions across both web and mobile platforms. Check out our capabilities, tech stack and what our wonderful team can do for you."],
        img:techGroup,
        buttonText:'Explore Our Tech Stack',
        link:'/our-services'
    }
]