import React from 'react'
import styled from 'styled-components'
import image from '../../assets/images/company.jpg'
import { Container, Section, Title, SubTitle, Paragraph, ButtonLink, Blue  } from '../Generic-Components/GenericStyledComponents';

const BestITSolutions = () => {
    return (
        <>
            <Section>
            <Container>
    
                <FlexContainer>
                    <ImageContainer>
                        <img src={image} alt="" />
                    </ImageContainer>
                    <Content>
                        <SubTitle>About OneX Services</SubTitle>
                        <Title>Well designed, dependable, robust <Blue>  IT solutions. </Blue></Title>
                         <Paragraph>We believe that digital platforms should empower and enhance an organisation. We believe that technical experts should be great to work with, and possess solution-oriented, positive attitudes. We take a laser focus on what we do and how we do it. We do not seek to be a one-stop digital shop, opting to concentrate on development, technology, recruitment and consultation.</Paragraph>
                         <Paragraph>
                         We help businesses and organisations transform their existing digital products or launch brand new ones. We work in partnership to deliver customer-centric solutions, push technology boundaries and deliver value at speed through agility.
                         </Paragraph>
                         <Paragraph>
                         We keep our sights firmly on delivering excellence, underpinned with an ethos of continuous improvement. We’re proud of our commitment to quality, our code standards, our processes and testing rigour.
                         </Paragraph>
                         <ButtonLink to='/contact-us'>Get In Touch</ButtonLink>
                    </Content>
                </FlexContainer>
            </Container>
            </Section>
        </>
      )
    }
    
    export default BestITSolutions
    


 
    const FlexContainer = styled.div`
    display: flex;
    flex-direction: column;
    @media (min-width:768px){
       flex-direction: row;
    }
    `
    const ImageContainer = styled.div`
       width: 100%;
       padding-right:3rem;
        img {
            width: 100%;
        }
    @media (min-width:768px){
       width: 50%;
    }
    `
    const Content = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 6rem;
    gap: 2.2rem;
    @media (min-width:768px){
       width: 50%;
       padding-top: auto;
    }
    `
    
