import React from 'react'
import { services } from './services-constant'
import AllServicesComponent from './AllServicesComponent'


const AllServicesContainer = () => {
  return (
    <>
      {services.map((serv,index)=>(
            <AllServicesComponent
            title={serv.title}
            subtitle={serv.subtitle}
            icons={serv.icons}
            bigParagraph={serv.bigParagraph}
            paragraph={serv.paragraph}
            link={serv.link}
            key={serv.link+index}
            inverse={ index%2==0 ? true: false}
            />
      )
      )}
    </>
  )
}

export default AllServicesContainer