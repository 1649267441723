import React from 'react'
import { content } from './our-work-constant'
import ComponentWithImages from '../Generic-Components/ComponentWithImages'

const OurWork = () => {


  return (
    <>
             {content.map((c,index)=>(
                <ComponentWithImages
                 img={c.img}
                 paragraphs={c.paragraphs}
                 title={c.title}
                 inverse={index%2==0?true:false}
                 buttonText={c.buttonText}
                 ul={c.ul?c.ul:false}
                 link={c.link?c.link:'/contact-us'}
                 key={index}
                />
            ))}
    </>
  )
}

export default OurWork