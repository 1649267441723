import React, { useState, useEffect } from "react";
import Navbar from "./Navbar";
import { routes } from "./navigation-constant";
import Drawer from "./Drawer";

const Navigation = () => {
  const [isOpen, setIsOpen] = useState(false);

useEffect(() => {
  
setTimeout(() => {

  if(!!isOpen){
    window.onscroll = function(){ window.scrollTo(0, 0) };
  }else{
    window.onscroll=null;
  }

}, 700);
  
}, [isOpen])


  const toggleDrawer = () => {
    window.scroll(0,0);
    setIsOpen(!isOpen);
  };

  return (
    <>
      <Drawer routes={routes} isOpen={isOpen} toggleDrawer={toggleDrawer} />
      <Navbar routes={routes} toggleDrawer={toggleDrawer} />
    </>
  );
};

export default Navigation;