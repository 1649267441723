
import womanHired from '../../assets/images/businesswoman-and-employee-shaking-hands.jpg'
import queue from '../../assets/images/horizontal-portrait-of-people-sit-in-queue-have-pleasant-conversation-with-each-other.jpg'

export const content = [
    {
        title:'Headhunters for digitally ambitious companies.',
        img:queue,
        subtitle:'Specialist IT Recruitment Agency',
        paragraphs:["The world of technology is moving at an incredibly rapid pace. Demand for top talent is already highly competitive, particularly for Leadership and Executive Technology positions. This growth is only set to skyrocket in the coming years, as cybersecurity, AI and commercialism rise to the forefront of in-demand capabilities and more businesses make the shift to remote work.","Due to the ever-changing nature of technology, your organisation needs to have the most skilled, talented and agile individuals to stay ahead of the game.","At OneX, our tech recruitment specialists offer you a tailored approach to finding the right hire for your business needs. With our extensive knowledge of the IT landscape and future trends, we help you find the candidates you need to succeed.","Our comprehensive sourcing strategy ensures that we find the right fit for your organisation — both now and in the future. Our specialists consult with you to determine the skills you require for your next hire, whether permanent or temporary. Once we know who your ideal candidate is, we get to work delivering. From Cyber Security specialists and Software Developer positions, to an IT Project Manager or Chief Technology Officer, we tap into our local and global talent network to find the perfect fit for your business."],

    },
    {
        img:womanHired,
        subtitle:'Contact Us Today',
        title:'Speak to us about your technology recruitment needs',
        paragraphs:["Whether you’re looking for Cloud Infrastructure specialists with DevOps experience, Data Analysts to scale up your business intelligence, or Software Developers and Engineers, our recruiters are here to help you.","We work with you to recruit the best talent for IT roles, as well as Leadership and Executive positions. We regularly help organisations source hires for in-demand jobs such as:",{ul:[
            "Service Desk Analyst","Project Manager","Java Developer","Desktop Support","Business Analyst","Technical Business Analyst","Application Support Engineer","Full-Stack Developer","Front-end/Back-end Developer","Systems Engineer","WordPress Developer","Website Developer","Program Manager"
        ]},"So, if you’re looking for a recruitment agency with specialist expertise in technology, talk to the team at OneX today."],
        buttonText:"Let's Talk",
        link:'/contact-us'
    }
]