import React from "react";
import styled from "styled-components";
import { HashLink as Link } from "react-router-hash-link";
import ExpandMenu from "./ExpandMenu";
import {
  TiSocialFacebook,
  TiSocialLinkedin,
  /*
  TiSocialTwitter,
  TiSocialInstagram,
  */
} from "react-icons/ti";
import logo_1 from "../../assets/images/logo_1.png";
import { FACEBOOK_URL, LINKEDIN_URL } from "../../constants";

const Drawer = ({ isOpen, toggleDrawer, routes }) => {
  return (
    <>
      {isOpen && <Backdrop onClick={toggleDrawer} />}
      <SDrawer isOpen={isOpen}>
        <RightNav>
          <SNavbarBrand to="/" onClick={toggleDrawer}>
            <img src={logo_1} alt="log" />
          </SNavbarBrand>
          <NavRoutes>
            {routes.map((route) => {
              if (route.subRoutes) {
                return (
                  <ExpandMenu
                    route={route}
                    key={route.name}
                    toggleDrawer={toggleDrawer}
                  />
                );
              }
              return (
                <NavRoute
                  onClick={toggleDrawer}
                  to={route.link}
                  key={route.name}
                >
                  {route.name}
                </NavRoute>
              );
            })}
          </NavRoutes>
          <SocialNav>
            <SocialMed target="_blank" href={FACEBOOK_URL}>
              <TiSocialFacebook />
            </SocialMed>
            <SocialMed target="_blank" href={LINKEDIN_URL}>
              <TiSocialLinkedin />
            </SocialMed>
            {/*
            <SocialMed  target='_blank'href="https://twitter.com">
              <TiSocialTwitter/>
            </SocialMed>
            <SocialMed  target='_blank'href="https://instagram.com">
              <TiSocialInstagram/>
            </SocialMed>
            */}
          </SocialNav>
        </RightNav>
      </SDrawer>
    </>
  );
};

export default Drawer;
const SNavbarBrand = styled(Link)`
  img {
    height: 9rem;
  }
`;
const Backdrop = styled.div`
  width: 100%;
  z-index: 100;
  height: 140vh;
  position: absolute;
  top: 0;
  left: 0;
  transition: 0.3s ease;
  background-color: rgba(0, 0, 0, 0.2);
`;
const SDrawer = styled.div`
  z-index: 999999;
  position: absolute;
  height: 140vh;
  top: 0;
  width: 60%;
  background-color: white;
  transition: 0.3s ease;
  transform: translateX(${(props) => (props.isOpen ? "0" : "-100%")});
`;

const RightNav = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 1rem;
`;
const NavRoutes = styled.div``;
const NavRoute = styled(Link)`
  display: flex;
  text-decoration: none;
  color: black;
  font-size: 3rem;
  padding: 0.5rem;
`;

const SocialNav = styled.div`
  display: flex;
  align-items: center;
  gap: 3rem;
`;
const SocialMed = styled.a`
  font-size: 3rem;
  text-decoration: none;
  color: black;
`;
